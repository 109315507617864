import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

export interface UserReviewsQuery {
  readonly title: string
  readonly reviews: readonly Review[]
}

interface Review {
  readonly text: string
  readonly name: string
  readonly showStars?: boolean
}

const useUserReviewsQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONUserReview {
      content: contentfulJson(contentful_id: { eq: "5zZETWF67zcUjWcIhjvVdp" }) {
        ...json
      }
    }
  `)

  const reviewsData = parseJSONDataFromContentful<UserReviewsQuery>(
    staticQuery,
    'content'
  )

  return reviewsData
}

export default useUserReviewsQuery
