import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingODMONParticipantAgreement() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (link: string, question: number) => {
      trackEvent({
        link: link,
        question,
        event: 'odmon-faq-link-click'
      })
    },
    [trackEvent]
  )
}
