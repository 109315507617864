import { useMemo } from 'react'

type BannerProps = {
  readonly title: string
  readonly subtitle: readonly {
    readonly text: string
    readonly type: string
  }[]
}

export function Banner({ title, subtitle }: BannerProps) {
  const text = useMemo(() => {
    return subtitle
      .map(item => {
        if (item.type === 'highlighted') {
          return `<span style="color: #F79E00">${item.text}</span>`
        } else {
          return item.text
        }
      })
      .join('')
  }, [subtitle])

  return (
    <div
      className="banner-background p-6 text-center lg:px-0 lg:py-6"
      data-testid="ODMON-Banner"
    >
      <h3 className="m-0 text-xl font-bold text-white">{title}</h3>
      <p
        className="m-0 mt-3 text-base text-white md:mt-1"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  )
}
