import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export const useTrackOdmonAddToCart = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (shields: number, kits: number, plan: string) => {
      trackEvent({
        eventCategory: 'quiz',
        eventAction: 'click',
        event: 'odmon-add-to-cart',
        shields,
        kits,
        plan
      })
    },
    [trackEvent]
  )
}
