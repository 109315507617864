import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf } from '@simplisafe/ewok'
import { z } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { ReactNode } from 'react'

import { partnerCampaignSchema } from './partnerCaptureSchema'

export const productSchema = z.object({
  sku: z.string(),
  name: z.string(),
  maxQuantity: z.number(),
  cartDescription: contentfulRichTextSchema.nullish(),
  cartUseCommercetoolsName: z.boolean().default(false)
})

export const monitoringPlanSchema = z
  .object({
    sku: z.string(),
    name: z.string(),
    cartDescription: z
      .object({ cartDescription: z.string().nullish() })
      .nullish(),
    locale: z.array(z.string())
  })
  .transform(({ cartDescription, ...rest }) => {
    return {
      cartDescription: cartDescription?.cartDescription,
      ...rest
    }
  })

export const packageSchema = z.object({
  sku: z.string(),
  name: z.string()
})

export const cartAccessorySchema = z.object({
  sku: z.string(),
  name: z.string(),
  description: contentfulRichTextSchema,
  image: contentfulImageSchema,
  maxQuantity: z.number()
})

export const cartAccessoriesSchema = z.object({
  accessories: z.object({
    nodes: z.array(cartAccessorySchema)
  })
})

export const useAllTopicsSchema = z.object({
  allContentfulProduct: z.object({
    edges: z.array(
      z.object({
        node: productSchema
      })
    )
  }),
  allContentfulMonitoringPlan: z.object({
    edges: z.array(
      z.object({
        node: monitoringPlanSchema
      })
    )
  }),
  allContentfulPackage: z.object({
    edges: z.array(
      z.object({
        node: packageSchema
      })
    )
  }),
  accessories: z.object({
    nodes: z.array(cartAccessorySchema)
  }),
  allContentfulPartnerCampaign: z.object({
    nodes: z.array(partnerCampaignSchema)
  })
})

export type Product = TypeOf<typeof productSchema>
export type MonitoringPlan = TypeOf<typeof monitoringPlanSchema>
export type Package = TypeOf<typeof packageSchema>
export type CartAccessoriesSchema = TypeOf<typeof cartAccessoriesSchema>
export type Accessory = TypeOf<typeof cartAccessorySchema>

export type ProductProviderProps = {
  readonly children: ReactNode | readonly ReactNode[]
  readonly products: readonly Product[]
  readonly plans: readonly MonitoringPlan[]
  readonly packages: readonly Package[]
}

export type ProductContextProps = {
  readonly getProduct: (_sku: string) => O.Option<Product>
  readonly getPlan: (_sku: string) => O.Option<MonitoringPlan>
  readonly getPackage: (_sku: string) => O.Option<Package>
}
