import React from 'react'

interface Props {
  readonly name: string
  readonly price: string
  readonly quantity: number
  readonly total?: boolean
}

function ListItem({ name, price, quantity, total = false }: Props) {
  const totalRow = total ? 'font-bold' : ''
  const priceStyle = price.length > 8 ? 'text-sm' : 'text-base'

  return (
    <div
      className="mt-0 w-full pt-1.5 md:pb-0 md:pt-1"
      data-component="OrderSummaryListItem"
    >
      <div className="flex justify-between py-2 md:justify-start md:py-1">
        <div className="flex items-center">
          {!total && (
            <span className="t-0 h-full pr-3" data-component="quantityText">
              {quantity !== 0 ? <>{quantity}x</> : <div className="w-4"></div>}
            </span>
          )}
          <span className={`max-w-[200px] text-base ${totalRow}`}>{name}</span>
        </div>
        <div className="mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex"></div>
        <span className={`${priceStyle} min-w-fit ${totalRow}`}>{price}</span>
      </div>
    </div>
  )
}

export default ListItem
