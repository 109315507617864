import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'
import { PurchaseKitsData, purchaseKitsData } from './schema'

export const usePurchaseKitsQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONPurchaseKitsScreenQuery {
      content: contentfulJson(contentful_id: { eq: "50zLmRGNsps1wGDw5OE7Ko" }) {
        ...json
      }
      asset: contentfulAsset(contentful_id: { eq: "iMG6gtCgaD86BUosvn3fQ" }) {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
    }
  `)

  const content = parseJSONDataFromContentful<PurchaseKitsData>(
    staticQuery,
    'content'
  )

  const asset = staticQuery.asset

  return purchaseKitsData.parse({ ...content, asset })
}
