import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

export interface PageBanner {
  readonly title: string
  readonly subtitle: readonly {
    readonly text: string
    readonly type: string
  }[]
}

const usePageBannerQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONbannerQuery {
      content: contentfulJson(contentful_id: { eq: "26rUWIWbzKKH7EtqTNJayd" }) {
        ...json
      }
    }
  `)

  const bannerData = parseJSONDataFromContentful<PageBanner>(
    staticQuery,
    'content'
  )

  return bannerData
}

export default usePageBannerQuery
