import React from 'react'

import { useIntroScreenQuery } from './useIntroScreenQuery'

export function IntroScreen() {
  const data = useIntroScreenQuery()

  return (
    <div data-testid="ODMON-Quiz-IntroScreen">
      <h1 className="font-default mb-6 mt-0 text-center text-2xl">
        {data.title}
      </h1>
      {data.sections.map((section, index) => (
        <section className="mb-4" key={`${section.title}-${index}`}>
          <h2 className="font-default mb-2 text-center text-xl">
            {section.title}
          </h2>
          <ul className="mt-2 list-none pl-1">
            {section.items.map((item, index) => (
              <li
                className="bg-plan-checkmark mb-3 bg-no-repeat pl-8 pt-1 text-sm"
                key={`${item}-${index}`}
              >
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </section>
      ))}
      <section className="mt-6 text-center">
        {data.notes.map((note, index) => (
          <p className="my-2 text-xs" key={`${note}-${index}`}>
            {note}
          </p>
        ))}
      </section>
    </div>
  )
}
