import classNames from 'classnames'
import React from 'react'

import { HardwarePriceImage } from '../../HardwarePriceImage'
import { usePurchaseKitsQuery } from './usePurchaseKitsQuery'

export interface PurchaseKitsProps {
  readonly userAmount: number
  readonly userOutdoorCameras: number
  readonly handleOptionChange: (option: number) => void
  readonly selectedOption: number
}

export function PurchaseKits({
  userAmount,
  userOutdoorCameras,
  selectedOption,
  handleOptionChange
}: PurchaseKitsProps) {
  const data = usePurchaseKitsQuery()

  const getOptions = (userOutdoorCameras: number) => {
    if (userAmount === 0) {
      return data.options.slice(userOutdoorCameras - 1, userOutdoorCameras)
    } else if (userAmount === 1) {
      return data.options.slice(userOutdoorCameras, userOutdoorCameras + 1)
    } else {
      return data.options.slice(0, userAmount)
    }
  }

  const options = getOptions(userOutdoorCameras)

  return (
    <div data-testid="ODMON-Quiz-PurchaseKits">
      <HardwarePriceImage
        currentPrice={data.currentPrice}
        image={data.asset}
        originalPrice={data.originalPrice}
        pricePosition="left"
      />
      <h1 className="font-default mb-6 mt-4 text-center text-xl">
        {data.title}
      </h1>
      <section className="mb-4">
        <h2 className="font-default mb-2 text-center text-base">
          {data.subtitle}
        </h2>
        <ul className="mt-2 list-none pl-1">
          {data.descriptions.map((item, index) => (
            <li
              className="bg-plan-checkmark mb-3 bg-no-repeat pl-8 pt-1 text-sm"
              key={`${item}-${index}`}
            >
              {item.split('.').map((line, index) => (
                <span
                  className={classNames({
                    'text-orange-700': line.includes('*')
                  })}
                  key={`${line}-${index}`}
                >
                  {line}
                </span>
              ))}
            </li>
          ))}
        </ul>
      </section>
      <section className="mb-6 mt-4 flex flex-col items-center text-left">
        <h2 className="font-default mb-4 text-center text-base">
          {data.optionsTitle}
        </h2>
        {options.map((option, index) => (
          <label
            className="mb-4 inline-flex w-32 items-center"
            key={`${option.label}-${index}`}
          >
            <input
              checked={selectedOption === option.value}
              className="border-neutral-medium-100 h-6 w-6 cursor-pointer rounded-full checked:bg-[#006FEE]"
              onChange={() => handleOptionChange(option.value)}
              type="radio"
              value={option.value}
            />
            <span className="ml-2">{option.label}</span>
          </label>
        ))}
      </section>
      <section className="mt-2 text-center">
        <p className="my-2 text-xs">{data.note}</p>
      </section>
    </div>
  )
}
