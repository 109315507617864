import React from 'react'

import ListItem from './ListItem'
import MonitoringReview from './MonitoringReview'
import { ReviewScreenData, useReviewScreenQuery } from './useReviewScreenQuery'

interface ReviewScreenProps {
  readonly purchaseOutdoorCameras: number
  readonly purchasePlan: number
  readonly purchaseKit: number
}

export function ReviewScreen({
  purchaseOutdoorCameras,
  purchasePlan,
  purchaseKit
}: ReviewScreenProps) {
  const data: ReviewScreenData = useReviewScreenQuery()
  const { products, nonProducts, title } = data.cart
  const productQuantities = [purchaseOutdoorCameras, purchaseKit]

  const total = products
    .map((product, i) => product.price * productQuantities[i])
    .reduce((total, productPrice) => total + productPrice, 0)

  return (
    <div className="flex flex-col gap-4" data-testid="ODMON-Quiz-ReviewScreen">
      <h2 className="font-default my-0 text-center text-2xl">{data.title}</h2>
      <p className="m-0 text-center text-sm">{data.subtitle}</p>
      <div className="bg-neutral-light-50 rounded-[0.625rem] p-4">
        <h3 className="m-0 w-full text-center text-lg">{title}</h3>
        <div className="mt-2">
          {products.map(
            (product, index) =>
              productQuantities[index] !== 0 && (
                <ListItem
                  key={product.name}
                  name={product.name}
                  price={`$${product.price * productQuantities[index]}`}
                  quantity={productQuantities[index]}
                />
              )
          )}
          {nonProducts.map(product => (
            <ListItem
              key={product.name}
              name={product.name}
              price={product.price.toString()}
              quantity={0}
            />
          ))}
        </div>
        <hr
          className={`border-neutral-medium-100 my-4 border border-b-0 border-solid`}
          data-component="Divider"
        />
        <ListItem
          key={'total'}
          name={data.cart.total}
          price={`$${total.toFixed(2)}`}
          quantity={0}
          total={true}
        />
      </div>
      <MonitoringReview protectionPlan={purchasePlan} subcart={data.subcart} />
      <section className="text-center">
        {data.disclaimer.map((note, index) => (
          <p className="my-2 text-xs" key={`${index}`}>
            {note}
          </p>
        ))}
      </section>
    </div>
  )
}
