import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import {
  affirmBannerSchema,
  guidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { z } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

const jsonSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

export const cityStatePageSchema = z.object({
  confidenceBar: jsonSchema,
  contentfulAffirmBanner: affirmBannerSchema,
  bestSystems: jsonSchema,
  dropdownRedirector: jsonSchema,
  guaranteeCopy: jsonSchema,
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  quoteWizardAsset: gatsbyImageSchema,
  benefitsImages: z.object({
    nodes: gatsbyImageSchema.array()
  })
})

export type CityStatePage = Zod.infer<typeof cityStatePageSchema>

export const useCityStatePageQuery = () => {
  return cityStatePageSchema.parse(
    useStaticQuery(graphql`
      fragment cityStateBodyQWBaseFragment on ContentfulQuoteWizard {
        __typename
        id: contentful_id
        contentful_id # This is needed for QWs embedded in a rich text
        jebbitUrl
        type
        buttonText
      }

      fragment cityStateBodyQWExperienceFragment on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulQuoteWizard {
            ...cityStateBodyQWBaseFragment
          }
        }
      }
      query CityStatePageQuery {
        confidenceBar: contentfulJson(
          contentful_id: { eq: "4MPjXZsXki3lB8IIfHPOau" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        contentfulAffirmBanner(
          contentful_id: { eq: "4VmV1UBXepTJFhRn8xKvni" }
        ) {
          description {
            raw
          }
          title
          internal {
            type
          }
        }
        bestSystems: contentfulJson(
          contentful_id: { eq: "247SODrmNYPOihVaq7mkR3" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        dropdownRedirector: contentfulJson(
          contentful_id: { eq: "2jVQg7nioeB1Qpg4Zxc0Gv" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        guaranteeCopy: contentfulJson(
          contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        guaranteeAsset: contentfulAsset(
          contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
        ) {
          ...cityStateBodyQWBaseFragment
          nt_experiences {
            ...cityStateBodyQWExperienceFragment
          }
        }
        expertSectionAsset: contentfulAsset(
          contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        comparisonTable: contentfulJson(
          contentful_id: { eq: "1zTyewofJ6lKWySS451GBg" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        quoteWizardAsset: contentfulAsset(
          contentful_id: { eq: "7FApwfEA91pNmqu35jzIoy" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        benefitsImages: allContentfulAsset(
          filter: {
            contentful_id: {
              in: ["4ybJ9ftX9ynMSD5tnEiAcV", "7e1hT6DJeh1F38xbRkYxY4"]
            }
          }
        ) {
          nodes {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    `)
  )
}
