import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

export interface FaqsQuery {
  readonly title: string
  readonly questions: readonly Question[]
  readonly support: {
    readonly title: string
    readonly contact: string
  }
}

export type FAQAnswer = Link | List | Text

export interface Link {
  readonly type: 'link'
  readonly content: string
  readonly href: string
  readonly code: string
  readonly className: string
}

export interface List {
  readonly type: 'list'
  readonly content: ReadonlyArray<FAQAnswer>
}

export interface Text {
  readonly type: 'text'
  readonly content: string
}

export interface Question {
  readonly question: string
  readonly answer: readonly ReadonlyArray<FAQAnswer>[]
}

const useFaqsQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONFaqsQuery {
      content: contentfulJson(contentful_id: { eq: "521iMtWRoFZpba1aeQQjze" }) {
        ...json
      }
    }
  `)

  const faqsData = parseJSONDataFromContentful<FaqsQuery>(
    staticQuery,
    'content'
  )

  return faqsData
}

export default useFaqsQuery
