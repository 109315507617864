import React from 'react'

import { useGetQuizButtons } from './useGetQuizButtons'

export function QuizButtons() {
  const { nextButton, backButton } = useGetQuizButtons()

  return (
    <div
      className="z-50 flex w-full flex-col gap-2 bg-white px-4 py-5 shadow-[0_-4px_4px_0px_rgba(0,0,0,0.1)]"
      data-testid="ODMON-Quiz-Footer"
    >
      <button
        className="btn btn-solid-primary"
        data-testid="ODMON-Quiz-NextButton"
        onClick={nextButton.onClick}
      >
        {nextButton.text}
      </button>
      {backButton ? (
        <button
          className="btn btn-outlined-primary btn-outlined  "
          data-testid="ODMON-Quiz-BackButton"
          onClick={backButton.onClick}
        >
          {backButton.text}
        </button>
      ) : null}
    </div>
  )
}
