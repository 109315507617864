import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { replaceTokensWithPrice } from '@ecomm/micro-copy'
import { Carousel, ContentfulRichText } from '@ecomm/shared-components'
import { CheckMark, InformationIcon } from '@ecomm/shared-icons'
import { Modal } from '@ecomm/ss-react-components'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useState } from 'react'

import { ContentCollectionSchema } from '../../config/schema'

export function EmbeddedPartnerPackage(props: ContentCollectionSchema) {
  const locale = useLocale()
  const { getPrice } = usePriceContext()
  const price = pipe(
    getPrice('SSEDSM2__4867366'),
    O.match(
      () => 0,
      price => price
    )
  )

  const packageDescriptionOptions: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: _ => (
        <span
          className="cursor-pointer"
          onClick={() => setIsOpenDetailsModal(true)}
        >
          <InformationIcon height={17} width={17} />
        </span>
      )
    }
  }

  const modalTextOptions: Options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="mx-0 my-4 p-0 pl-4">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (_, children) => {
        return (
          <li className="ml-0 flex items-center gap-2 py-2">
            <CheckMark className="text-complementary-sage-100 h-3 w-3" />
            {children}
          </li>
        )
      }
    }
  }

  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false)

  const [
    carousel,
    title,
    descriptionTitle1,
    descriptionTitle2,
    packageDescription,
    modalText
  ] = props.content
  return (
    <>
      <div className="mx-auto h-[340px] w-[398px] md:h-[600px] md:w-[623px]">
        <Carousel arrowControls autoPlaySpeed={5000} dotControls infinite>
          {carousel.items.map(({ image }, idx) => (
            <ContentfulImage
              className="h-full rounded-lg bg-transparent"
              key={idx}
              {...image}
              height={600}
              quality={65}
            />
          ))}
        </Carousel>
      </div>
      <div className="px-4 md:px-0">
        <div className="prose-headings:font-thin prose-headings:mt-8 -mb-2 mt-9 md:-mb-1">
          <ContentfulRichText raw={title.text.raw} />
        </div>
        <div className="prose-p:inline prose-p:text-complementary-red-100 prose-p:text-2xl prose-headings:inline prose-headings:text-2xl prose-headings:font-normal mb-2">
          <ContentfulRichText raw={descriptionTitle1.text.raw} />
          <ContentfulRichText raw={descriptionTitle2.text.raw} />
        </div>
        <div className="prose-p:mb-2 prose-p:text-sm w-11/12 columns-2">
          <ContentfulRichText
            optionsCustom={packageDescriptionOptions}
            raw={packageDescription.text.raw}
          />
        </div>
      </div>
      <Modal
        isOpen={isOpenDetailsModal}
        onRequestClose={() => setIsOpenDetailsModal(false)}
      >
        <div className="prose-p:text-base md:prose-p:text-lg prose-p:m-0 prose-headings:font-medium prose-headings:text-3xl md:prose-headings:text-4xl prose-headings:my-4 px-8 py-4 md:py-6 lg:py-8">
          <ContentfulRichText
            optionsCustom={modalTextOptions}
            raw={replaceTokensWithPrice(locale, modalText.text.raw, price)}
          />
        </div>
      </Modal>
    </>
  )
}
