import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export type TrackPlacement = 'atf' | 'nav'

export function useTrackingODMONEarlyAccessClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (placement: TrackPlacement) => {
      trackEvent({
        placement,
        event: 'get-early-access-click'
      })
    },
    [trackEvent]
  )
}
