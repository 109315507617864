import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const purchaseKitsData = z.object({
  title: z.string(),
  subtitle: z.string(),
  descriptions: z.array(z.string()),
  optionsTitle: z.string(),
  options: z.array(
    z.object({
      label: z.string(),
      value: z.number()
    })
  ),
  note: z.string(),
  asset: gatsbyImageSchema,
  currentPrice: z.string(),
  originalPrice: z.string()
})

export type PurchaseKitsData = TypeOf<typeof purchaseKitsData>
