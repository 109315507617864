import { Header } from '@ecomm/header-redesigned'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import { Footer } from '@ecomm/shared-components'
import { Link } from 'gatsby'

import { use2gUpgradeFooterQuery } from '../../hooks/use2gUpgradeFooterQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import { useUpgrade2GSuccessPageQuery } from '../../hooks/useUpgrade2GSuccessPageQuery'

type Upgrade2GSuccessContentType = {
  readonly successTitle: string
  readonly successDescription: string
}

export default function Upgrade2gSuccessTemplate() {
  const { footerData } = use2gUpgradeFooterQuery()
  const headerData = useHeaderRedesignQuery()

  const data: Upgrade2GSuccessContentType = parseJSONDataFromContentful(
    useUpgrade2GSuccessPageQuery(),
    'upgradeSuccess'
  )
  return (
    <div>
      <Header {...headerData} />
      <div className="flex w-screen flex-col items-center p-4 py-8 md:p-8 lg:px-16 lg:pb-16">
        <div className="mb-4 flex w-full max-w-6xl flex-col lg:py-16">
          <h1 className="h3">{data.successTitle}</h1>
          <div className="paragraph whitespace-pre-line">
            {data.successDescription}
          </div>
          <Link className="btn btn-solid-primary w-fit" to="/">
            Go to Home Page
          </Link>
        </div>
      </div>
      {footerData ? <Footer data={footerData.footer} type="Full" /> : null}
    </div>
  )
}
