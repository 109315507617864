import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'

export interface ReviewScreenData {
  readonly title: string
  readonly subtitle: string
  readonly cart: Cart
  readonly subcart: Subcart
  readonly disclaimer: readonly string[]
}

export interface Cart {
  readonly title: string
  readonly products: readonly Product[]
  readonly nonProducts: readonly Product[]
  readonly total: string
}

export interface Product {
  readonly name: string
  readonly price: number
}

export interface Subcart {
  readonly title: string
  readonly products: readonly Product[]
}

export const useReviewScreenQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONReviewScreenQuery {
      content: contentfulJson(contentful_id: { eq: "1zmrJI3tr7hQBuigkI5rvZ" }) {
        ...json
      }
    }
  `)

  const textContent = parseJSONDataFromContentful<ReviewScreenData>(
    staticQuery,
    'content'
  )

  return textContent
}
