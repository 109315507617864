import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackDownloadAppClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (platform: string) => {
      trackEvent({
        platform,
        event: 'odmon-app-download-click'
      })
    },
    [trackEvent]
  )
}
