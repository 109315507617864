import { TrackingData } from '@ecomm/tracking'
import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'

const storageFlag = 'odmonPreAuthTracked'

export const useTrackPreAuthVisit = (
  trackEvent: (data: Partial<TrackingData>) => void
) => {
  return () => {
    const track = () => {
      trackEvent({ event: 'odmon-beta-pre-auth-visit' })
      sessionStorageSet(storageFlag, '1')
    }

    !sessionStorageGet(storageFlag) && track()
  }
}
