import {
  OdmonChoiceFlowEvent,
  ODMONQuizScreens,
  useTrackOdmonAddToCart,
  useTrackOdmonModalChoiceFlow
} from '@ecomm/tracking'
import { useMemo } from 'react'

import { useQuizState } from './QuizContext'
import { QuizSteps } from './QuizReducer'

export type QuizTrackingData = Record<
  number,
  (event: OdmonChoiceFlowEvent) => () => void
>

export const useCreateQuizTrackingData = (): QuizTrackingData => {
  const state = useQuizState()
  const trackChoiceFlow = useTrackOdmonModalChoiceFlow()
  const trackAddToCart = useTrackOdmonAddToCart()

  return useMemo(
    () =>
      <const>{
        [QuizSteps.INTRO]: (event: OdmonChoiceFlowEvent) => () => {
          trackChoiceFlow(ODMONQuizScreens.INTRO, event, 0)
        },
        [QuizSteps.EXISTING_OUTDOOR_CAMERAS]:
          (event: OdmonChoiceFlowEvent) => () => {
            trackChoiceFlow(
              ODMONQuizScreens.EXISTING_OUTDOOR_CAMERAS,
              event,
              state.existingOutdoorCameras === 4
                ? '+4'
                : state.existingOutdoorCameras
            )
          },
        [QuizSteps.PURCHASE_CAMERA]: (event: OdmonChoiceFlowEvent) => () => {
          trackChoiceFlow(
            ODMONQuizScreens.PURCHASE_CAMERA,
            event,
            state.purchaseOutdoorCameras
          )
        },
        [QuizSteps.PURCHASE_KITS]: (event: OdmonChoiceFlowEvent) => () => {
          trackChoiceFlow(
            ODMONQuizScreens.PURCHASE_KITS,
            event,
            state.purchaseKit
          )
        },
        [QuizSteps.PURCHASE_PLAN]: (event: OdmonChoiceFlowEvent) => () => {
          trackChoiceFlow(
            ODMONQuizScreens.PURCHASE_PLAN,
            event,
            state.purchasePlan === 9.99 ? '24 hour' : 'overnight'
          )
        },
        [QuizSteps.REVIEW]: (event: OdmonChoiceFlowEvent) => () => {
          if (event === OdmonChoiceFlowEvent.ADD_TO_CART) {
            return trackAddToCart(
              state.purchaseOutdoorCameras,
              state.purchaseKit,
              state.purchasePlan === 9.99 ? '24 hour' : 'overnight'
            )
          } else {
            return trackChoiceFlow(ODMONQuizScreens.REVIEW, event, 0)
          }
        }
      },
    [
      state.purchaseOutdoorCameras,
      state.purchaseKit,
      state.purchasePlan,
      state.existingOutdoorCameras,
      trackChoiceFlow,
      trackAddToCart
    ]
  )
}
