import { FormikHelpers } from 'formik'
import { TypeOf, z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const EMAIL_ERROR = 'Please enter a valid email address.'
const formFieldsSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR),
  phone: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string()
})

const formQuerySchema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  fields: z
    .object({
      label: z.string(),
      placeholder: z.string(),
      type: z.enum(['Text', 'Email', 'Telephone', 'Dropdown']),
      name: z.string()
    })
    .array(),
  button: z.object({
    text: z.string()
  })
})

export const validationSchema = toFormikValidationSchema(formFieldsSchema)
export type FormQuerySchema = TypeOf<typeof formQuerySchema>
export type FormFieldsSchema = TypeOf<typeof formFieldsSchema>
export type OnSubmit = (
  values: FormFieldsSchema,
  formikHelpers: FormikHelpers<FormFieldsSchema>
) => void
export type SubmissionResultProps = {
  readonly result: 'error' | 'pending' | 'success'
}
