import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingODMONFaqsClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (question: string, action: 'collapse' | 'expand') => {
      trackEvent({
        action,
        question,
        event: 'odmon-faq-click'
      })
    },
    [trackEvent]
  )
}
