import { useLockBodyScroll, useOnClickOutside } from '@ecomm/shared-hooks'
import React, { ReactNode, useRef } from 'react'

interface Props {
  readonly isOpen: boolean
  readonly children: ReactNode
  readonly onClose: () => void
}

export function QuizModal({ isOpen, children, onClose }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const modalVisibility = isOpen ? 'block' : 'hidden'

  useLockBodyScroll(isOpen, false)
  useOnClickOutside(onClose, ref)

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto ${modalVisibility}`}
      data-component="Modal"
      data-testid="ODMON-Quiz-Modal"
    >
      <div className="safe-h-screen flex items-center justify-center">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div
          className="z-50 mx-auto flex h-[100svh] w-screen flex-col bg-white shadow-lg md:h-[754px] md:w-[375px]"
          ref={ref}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
