import React from 'react'

import { FaqsQuery } from '../../hooks/useFaqsQuery'
import { Accordion } from './Accordion'

export function FAQs({ title, questions, support }: FaqsQuery) {
  return (
    <div
      className="flex flex-col justify-center gap-8"
      data-testid="ODMON-FAQs"
    >
      <h2 className="h2 m-0 text-center">{title}</h2>
      <div className="flex flex-col gap-5">
        {questions.map((question, index) => (
          <div
            className={'bg-white first:rounded-t-3xl last:rounded-b-3xl'}
            key={index.toString()}
          >
            <Accordion {...question} questionNumber={index + 1} />
          </div>
        ))}
      </div>
      <div className="flex w-full flex-col text-center">
        <div className="font-bold">{support.title}</div>
        <div>{support.contact}</div>
      </div>
    </div>
  )
}
