import { graphql, useStaticQuery } from 'gatsby'

const useFooterQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query BetaLanderTemplateQuery {
      footer: contentfulFooter(contentful_id: { eq: "VuWNEeaRkwExs89iai0RQ" }) {
        ...footer
      }
    }
  `)

  return staticQuery.footer
}

export default useFooterQuery
