export enum QuizSteps {
  INTRO,
  EXISTING_OUTDOOR_CAMERAS,
  PURCHASE_CAMERA,
  PURCHASE_KITS,
  PURCHASE_PLAN,
  REVIEW
}

export interface QuizState {
  readonly currentStep: QuizSteps
  readonly existingOutdoorCameras: number
  readonly purchaseOutdoorCameras: number
  readonly purchaseKit: number
  readonly purchasePlan: number
}

export enum QuizActionTypes {
  NEXT,
  BACK,
  SET_EXISTING_CAMERAS,
  SET_PURCHASE_CAMERAS,
  SET_PURCHASE_KIT,
  SET_PURCHASE_PLAN
}

export type QuizActions = {
  readonly type: QuizActionTypes
  readonly payload: number
}

const isQuizStep = (nextOrPrevStep: number): nextOrPrevStep is QuizSteps =>
  QuizSteps.INTRO <= nextOrPrevStep && nextOrPrevStep <= QuizSteps.REVIEW

const getNextStep = (currentStep: number, hasMaxOutdoorCameras: boolean) => {
  const shouldSkipNextStep = currentStep === 1 && hasMaxOutdoorCameras
  const nextStep = shouldSkipNextStep ? currentStep + 2 : currentStep + 1

  return isQuizStep(nextStep) ? nextStep : currentStep
}

const getPreviousStep = (
  currentStep: number,
  hasMaxOutdoorCameras: boolean
) => {
  const shouldSkipPrevStep = currentStep === 3 && hasMaxOutdoorCameras
  const prevStep = shouldSkipPrevStep ? currentStep - 2 : currentStep - 1

  return isQuizStep(prevStep) ? prevStep : currentStep
}

export function QuizReducer(state: QuizState, action: QuizActions) {
  const MAX_EXISTENT_CAMERAS_TO_SKIP_STEP_2 = 2
  const hasMaxOutdoorCameras =
    state.existingOutdoorCameras >= MAX_EXISTENT_CAMERAS_TO_SKIP_STEP_2

  switch (action.type) {
    case QuizActionTypes.NEXT:
      return {
        ...state,
        currentStep: getNextStep(state.currentStep, hasMaxOutdoorCameras)
      }
    case QuizActionTypes.BACK:
      return {
        ...state,
        currentStep: getPreviousStep(state.currentStep, hasMaxOutdoorCameras)
      }
    case QuizActionTypes.SET_EXISTING_CAMERAS:
      return {
        ...state,
        existingOutdoorCameras: action.payload,
        purchaseOutdoorCameras: 0,
        // We set the existingOutdoorCameras as the default purchase kit
        purchaseKit: action.payload
      }
    case QuizActionTypes.SET_PURCHASE_CAMERAS:
      return {
        ...state,
        purchaseOutdoorCameras: action.payload,
        purchaseKit:
          state.existingOutdoorCameras !== 0
            ? action.payload + 1
            : action.payload
      }
    case QuizActionTypes.SET_PURCHASE_KIT:
      return { ...state, purchaseKit: action.payload }
    case QuizActionTypes.SET_PURCHASE_PLAN:
      return { ...state, purchasePlan: action.payload }
    default:
      return state
  }
}

export const initialState: QuizState = {
  currentStep: QuizSteps.INTRO,
  purchaseOutdoorCameras: 1,
  existingOutdoorCameras: 0,
  purchaseKit: 1,
  purchasePlan: 9.99
}
