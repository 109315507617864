import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { usePurchasePlanQuery } from './usePurchasePlanQuery'

export interface PurchasePlanProps {
  readonly handleOptionChange: (option: number) => void
  readonly selectedOption: number
}

export function PurchasePlan({
  selectedOption,
  handleOptionChange
}: PurchasePlanProps) {
  const data = usePurchasePlanQuery()

  return (
    <div data-testid="ODMON-Quiz-PurchasePlan">
      <h1 className="font-default mb-4 mt-0 text-center text-2xl">
        {data.title}
      </h1>
      <p className="mb-4 text-center text-sm">{data.description}</p>
      <section>
        {data.plans.map(plan => (
          <div
            className={classNames(
              'hover:border-complementary-blue-100 mb-4 w-full cursor-pointer rounded-xl border-2 border-solid p-2',
              {
                'border-complementary-blue-100 border-[6px]':
                  selectedOption === plan.price.value,
                'border-gray-300': selectedOption !== plan.price.value
              }
            )}
            key={plan.id}
            onClick={() => handleOptionChange(plan.price.value)}
          >
            <div className="flex items-baseline justify-between">
              <div className="w-1/4">
                <GatsbyImage
                  alt={plan.asset.title}
                  className="h-full w-full rounded-lg object-cover"
                  image={plan.asset.gatsbyImageData}
                />
              </div>
              <div className="ml-4 w-3/4">
                <span className="text-xl font-semibold">{plan.name}</span>
                <p className="mb-2 mt-2 text-sm">{plan.description}</p>
                <div className="flex items-start">
                  <div className="mr-1">
                    <input
                      aria-labelledby={plan.id}
                      checked={selectedOption === plan.price.value}
                      className="border-neutral-medium-100 h-6 w-6 cursor-pointer rounded-full checked:bg-[#006FEE] hover:bg-[#006FEE]"
                      readOnly={true}
                      type="radio"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-bold text-orange-700"
                      id={plan.id}
                    >
                      {plan.price.label}
                    </label>
                    <span className="text-10px block">{plan.priceNote}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
      <section className="text-center">
        {data.notes.map((note, index) => (
          <p className="my-2 text-xs" key={`${note}-${index}`}>
            {note}
          </p>
        ))}
      </section>
    </div>
  )
}
