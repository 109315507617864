import { headerRedesignSchema } from '@ecomm/header-redesigned'
import { graphql, useStaticQuery } from 'gatsby'

export const useHeaderRedesignQuery = () => {
  return headerRedesignSchema.parse(
    useStaticQuery(graphql`
      fragment qwBaseFragment on ContentfulQuoteWizard {
        __typename
        id: contentful_id
        jebbitUrl
        type
        buttonText
      }

      fragment qwExperienceFragment on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulQuoteWizard {
            ...qwBaseFragment
          }
        }
      }

      query HeaderRedesignQuery {
        images: allContentfulAsset(
          filter: {
            contentful_id: {
              in: [
                "4ISQo32VbzFoCuz0sPSEIb"
                "3X477mLg2byYfBD0MzmdMe"
                "30qjwcrVYFr7ZLmfts9yID"
                "5C0JVOfwJLkJK6WF6NZ9PW"
                "6pToedl3jApJdlviDDSSFi"
                "2H1lDKAlSNUC4E4nSolJIV"
                "2n9okk7IQZPtmVYvSD3KXg"
                "2S40uNCo4M8wvKgkKPQ17m"
                "4bC09EpnddkbaJd0Rw1GS8"
                "26vernKYKmkWfqIw6BiBFm"
                "75LoLEcNP7nJnbsRPpG3W6"
                "2gnj0MqS2RvadjpecJDG6K"
              ]
            }
          }
        ) {
          nodes {
            contentful_id
            gatsbyImageData(placeholder: BLURRED)
            description
          }
        }
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "1L1QlxR38aLrLL718EWGUh" }
        ) {
          ...qwBaseFragment
          nt_experiences {
            ...qwExperienceFragment
          }
        }
      }
    `)
  )
}
