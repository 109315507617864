import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

export interface HardwarePriceImageProps {
  readonly image: GatsbyImageSchema
  readonly currentPrice: string
  readonly originalPrice: string
  readonly pricePosition: 'left' | 'right'
}

export function HardwarePriceImage({
  image,
  currentPrice,
  originalPrice,
  pricePosition
}: HardwarePriceImageProps) {
  return (
    <section
      className="relative flex items-center justify-center"
      data-testid="ODMON-Quiz-HardwarePriceImage"
    >
      <div
        className={classNames('absolute top-4 z-10', {
          'left-4': pricePosition === 'left',
          'right-4': pricePosition === 'right'
        })}
      >
        <h2 className="font-default m-0 text-3xl font-bold text-orange-700">
          {currentPrice}
        </h2>
        <h2 className="font-default m-0 text-base font-bold ">
          <s>{originalPrice}</s>
        </h2>
      </div>
      <div className="flex h-32 items-center justify-center rounded-lg">
        <GatsbyImage
          alt={image.title}
          className="h-full w-full rounded-lg object-cover"
          image={image.gatsbyImageData}
        />
      </div>
    </section>
  )
}
