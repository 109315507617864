import { ChevronDown } from '@ecomm/ss-react-components'
import { useTrackingODMONFaqsClick } from '@ecomm/tracking'
import { voidFn } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { useState } from 'react'

import { FAQAnswer } from '../../hooks/useFaqsQuery'
import Answer from './Answer'

export type AccordionProps = {
  readonly answer: readonly ReadonlyArray<FAQAnswer>[]
  readonly onToggle?: (isOpen: boolean) => void
  readonly question: string
  readonly questionNumber: number
}

export function Accordion({
  answer,
  question,
  questionNumber,
  onToggle = voidFn
}: AccordionProps) {
  const [open, setOpen] = useState(false)
  const trackClick = useTrackingODMONFaqsClick()

  const handleOnClick = () => {
    trackClick(question, open ? 'collapse' : 'expand')
    setOpen(o => !o)
    onToggle && onToggle(!open)
  }

  return (
    <div className="bg-neutral-light-50 flex flex-col rounded-[20px] px-5 py-5 md:px-10 md:py-8">
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={handleOnClick}
      >
        <span className=" text-lg font-medium md:block md:text-2xl md:font-medium">
          {question}
        </span>
        <span className="min-w-[25px]">
          <ChevronDown
            className={classNames('h-6 w-6 transition-transform', {
              'rotate-180': open
            })}
          />
        </span>
      </div>
      <div
        className={classNames('mr-1 md:mr-[5.75rem]', { hidden: !open })}
        data-component="AnswerContainer"
      >
        <div className="prose-p:mb-0 pt-3 md:pt-6">
          <Answer answer={answer} questionNumber={questionNumber} />
        </div>
      </div>
    </div>
  )
}
