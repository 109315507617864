import { setOdmonBetaExperience } from '@ecomm/checkout/utils'
import { logError } from '@ecomm/error-handling'
import { OdmonChoiceFlowEvent } from '@ecomm/tracking'
import { set as sessionStorageSet } from '@ecomm/utils'
import { navigate } from 'gatsby'
import { useMemo } from 'react'

import { useBuildCart } from '../../../hooks/useBuildCart'
import { useQuizDispatch, useQuizState } from '../QuizContext'
import { QuizActionTypes, QuizSteps } from '../QuizReducer'
import {
  QuizTrackingData,
  useCreateQuizTrackingData
} from '../useCreateQuizTrackingData'

export enum ButtonLabels {
  NEXT = 'Next',
  BACK = 'Back',
  CHOOSE_THIS_PLAN = 'Choose this plan',
  PROCEED_TO_CHECKOUT = 'Proceed to checkout'
}

type QuizButtons = Record<
  QuizSteps,
  {
    readonly nextButton: { readonly text: string; readonly onClick: () => void }
    readonly backButton?: {
      readonly text: string
      readonly onClick: () => void
    }
  }
>

const buildNextButton =
  (trackingData: QuizTrackingData) =>
  (
    quizStep: QuizSteps,
    clickHandler: () => void,
    label: ButtonLabels = ButtonLabels.NEXT,
    event: OdmonChoiceFlowEvent = OdmonChoiceFlowEvent.NEXT_CLICK
  ) => ({
    text: label,
    onClick: () => {
      const trackEvent = trackingData[quizStep](event)

      trackEvent()
      clickHandler && clickHandler()
    }
  })

const buildBackButton =
  (trackingData: QuizTrackingData) =>
  (quizStep: QuizSteps, clickHandler: () => void) => ({
    text: ButtonLabels.BACK,
    onClick: () => {
      const trackEvent = trackingData[quizStep](OdmonChoiceFlowEvent.BACK_CLICK)

      trackEvent()
      clickHandler && clickHandler()
    }
  })

const handleCartBuildFailure = () =>
  logError(new Error('An error occurred while building shopping cart'))
const handleCartBuildSuccess = () => {
  setOdmonBetaExperience()
  navigate('/cart/checkout')
}

export const useGetQuizButtons = () => {
  const state = useQuizState()
  const dispatch = useQuizDispatch()
  const trackingData = useCreateQuizTrackingData()
  const buildCart = useBuildCart(handleCartBuildFailure, handleCartBuildSuccess)

  const _buildNextButton = buildNextButton(trackingData)
  const _buildBackButton = buildBackButton(trackingData)

  const buttons: QuizButtons = useMemo(() => {
    const savePurchaseLocally = () => {
      sessionStorageSet(
        'odmon-cameras',
        state.purchaseOutdoorCameras.toString()
      )
      sessionStorageSet(
        'odmon-plan',
        state.purchasePlan === 9.99 ? '24 hour' : 'overnight'
      )
      sessionStorageSet('odmon-kit', state.purchaseKit.toString())
    }

    return {
      [QuizSteps.INTRO]: {
        nextButton: _buildNextButton(QuizSteps.INTRO, () =>
          dispatch({ type: QuizActionTypes.NEXT, payload: 0 })
        )
      },
      [QuizSteps.EXISTING_OUTDOOR_CAMERAS]: {
        nextButton: _buildNextButton(QuizSteps.EXISTING_OUTDOOR_CAMERAS, () =>
          dispatch({ type: QuizActionTypes.NEXT, payload: 0 })
        ),
        backButton: _buildBackButton(QuizSteps.EXISTING_OUTDOOR_CAMERAS, () =>
          dispatch({ type: QuizActionTypes.BACK, payload: 0 })
        )
      },
      [QuizSteps.PURCHASE_CAMERA]: {
        nextButton: _buildNextButton(QuizSteps.PURCHASE_CAMERA, () =>
          dispatch({ type: QuizActionTypes.NEXT, payload: 0 })
        ),
        backButton: _buildBackButton(QuizSteps.PURCHASE_CAMERA, () =>
          dispatch({ type: QuizActionTypes.BACK, payload: 0 })
        )
      },
      [QuizSteps.PURCHASE_KITS]: {
        nextButton: _buildNextButton(QuizSteps.PURCHASE_KITS, () =>
          dispatch({ type: QuizActionTypes.NEXT, payload: 0 })
        ),
        backButton: _buildBackButton(QuizSteps.PURCHASE_KITS, () =>
          dispatch({ type: QuizActionTypes.BACK, payload: 0 })
        )
      },
      [QuizSteps.PURCHASE_PLAN]: {
        nextButton: _buildNextButton(
          QuizSteps.PURCHASE_PLAN,
          () => dispatch({ type: QuizActionTypes.NEXT, payload: 0 }),
          ButtonLabels.CHOOSE_THIS_PLAN
        ),
        backButton: _buildBackButton(QuizSteps.PURCHASE_PLAN, () =>
          dispatch({ type: QuizActionTypes.BACK, payload: 0 })
        )
      },
      [QuizSteps.REVIEW]: {
        nextButton: _buildNextButton(
          QuizSteps.REVIEW,
          () => {
            savePurchaseLocally()
            buildCart(state.purchaseOutdoorCameras, state.purchaseKit)
          },
          ButtonLabels.PROCEED_TO_CHECKOUT,
          OdmonChoiceFlowEvent.ADD_TO_CART
        ),
        backButton: _buildBackButton(QuizSteps.REVIEW, () =>
          dispatch({ type: QuizActionTypes.BACK, payload: 0 })
        )
      }
    }
  }, [
    _buildBackButton,
    _buildNextButton,
    buildCart,
    dispatch,
    state.purchaseKit,
    state.purchaseOutdoorCameras,
    state.purchasePlan
  ])

  return {
    nextButton: buttons[state.currentStep].nextButton,
    backButton: buttons[state.currentStep].backButton
  }
}
