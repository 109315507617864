import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { voidFn } from '@simplisafe/ewok'
import { Link } from 'gatsby'
import { useTracking } from 'react-tracking'

type HeaderProps = {
  readonly displayButton?: boolean
  readonly onClick?: VoidFunction
}

function LogoLink() {
  const { trackEvent } = useTracking()

  return (
    <Link
      aria-label="Home page"
      className="flex w-28 grow md:grow-0 min-[1126px]:ml-0 min-[1126px]:w-[150px]"
      onClick={() => {
        trackEvent({
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation',
          label: 'logo'
        })
        trackNavigationClick({
          action: 'click',
          navElement: 'logo'
        })
      }}
      to="/"
    >
      <SimpliSafeLogo className="h-7 w-28 min-[1126px]:h-9 min-[1126px]:w-40" />
    </Link>
  )
}

export function Header({
  displayButton = true,
  onClick = voidFn
}: HeaderProps) {
  return (
    <header
      className="bg-neutral-black sticky top-0 z-10 w-full text-inherit lg:z-20"
      data-testid="ODMON-Header"
    >
      <nav className="py-p-4 max-w-8xl mx-auto flex h-[65px] items-center gap-4 px-3 max-[1125px]:justify-between md:gap-8 md:px-8 md:py-0 min-[1126px]:h-[78px]">
        <div className="md:flex-1">
          <LogoLink />
        </div>
        {displayButton ? (
          <div className="mb-2 mt-2">
            <button
              className="btn btn-solid-primary block text-base text-white"
              onClick={onClick}
            >
              Get early access
            </button>
          </div>
        ) : null}
      </nav>
    </header>
  )
}
