import { graphql, useStaticQuery } from 'gatsby'

import { VideoSectionProps } from '../components/VideoSection'
import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

const useVideoSectionQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONVideoBanner {
      content: contentfulJson(contentful_id: { eq: "3bBCIVK9T8nH55f8OQTx2C" }) {
        ...json
      }
    }
  `)

  const contentData = parseJSONDataFromContentful<
    Omit<VideoSectionProps, 'video'>
  >(staticQuery, 'content')

  const video = {
    webmLink: 'https://assets.simplisafe.com/om/om_video.webm',
    mp4Link: 'https://assets.simplisafe.com/om/om_video.mp4',
    captionsSrc: 'https://assets.simplisafe.com/om/om_video_v2.vtt',
    posterImage: 'https://assets.simplisafe.com/om/om_poster.jpg'
  }

  return {
    title: contentData.title,
    text: contentData.text,
    button: contentData.button,
    video: video
  }
}

export default useVideoSectionQuery
