import { footerSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const parseIframePageSchema = <T extends { readonly footer: U }, U>(
  data: T
): IframePageSchema => {
  const { footer } = iframePageSchema.parse(data)

  return {
    footer
  }
}

export const iframePageSchema = z.object({
  footer: footerSchema.nullish()
})

export type IframePageSchema = TypeOf<typeof iframePageSchema>
