import React from 'react'

import { Subcart } from './useReviewScreenQuery'

interface Props {
  readonly subcart: Subcart
  readonly protectionPlan: number
}
function MonitoringReview({ subcart, protectionPlan }: Props) {
  const product = subcart.products.find(({ price }) => price === protectionPlan)

  return product ? (
    <div className="bg-neutral-light-50 rounded-[0.625rem] p-4">
      <h3 className="m-0 w-full text-center text-lg">{subcart.title}</h3>
      <div
        className="mt-2 w-full pt-1.5 md:pb-0"
        data-component="OrderSummaryListItem"
      >
        <div className="flex justify-between py-2 md:justify-start md:py-1">
          <span className={`max-w-[200px] text-base`}>{product.name}</span>
          <div className="mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex"></div>
          <div className="flex flex-col">
            <span className={`min-w-fit font-bold`}>${product.price}/mo</span>
            <div>add&apos;l*</div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default MonitoringReview
