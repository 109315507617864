import { INTERACTIVE_MONITORING } from '@ecomm/data-constants'
import { PriceProvider } from '@ecomm/data-price'
import {
  ContentfulRichText,
  CountryRedirectModal,
  Footer
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { Locale, SEO } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'
import { match } from 'ts-pattern'

import { EmbeddedPartnerForm } from '../components/EmbeddedPartnerForm'
import { EmbeddedPartnerHeader } from '../components/EmbeddedPartnerHeader'
import { EmbeddedPartnerPackage } from '../components/EmbeddedPartnerPackage'
import { FloorPlanCarouselWrapper } from '../components/FloorPlanCarouselWrapper '
import { MonitoringWrapper } from '../components/Monitoring'
import { embeddedPartnerPageSchema } from '../config/schema'

export type PageContext = {
  readonly locale: Locale
}

export type Props<T> = Pick<PageProps<T, PageContext>, 'data' | 'pageContext'>

export default function EmbeddedPartnerPageTemplate<T>({
  data,
  pageContext: { locale }
}: Props<T>) {
  const {
    pageTitle,
    layout: { footer, header, components },
    seo,
    partnerName
  } = embeddedPartnerPageSchema.parse(data).contentfulEmbeddedPartnerPage

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <PriceProvider locale={locale} skus={[INTERACTIVE_MONITORING]}>
        <SEO
          isNofollow={seo.isNofollow}
          isNoindex={seo.isNoindex}
          lang={locale}
          metaDescription={seo.metaDescription?.metaDescription || ''}
          metaTitle={seo.metaTitle}
        />
        <CountryRedirectModal />
        <EmbeddedPartnerHeader {...header} />
        <div className="relative z-0 w-full whitespace-pre-line">
          <div className="max-w-8xl mx-auto mb-16 flex flex-col gap-5 px-4 pt-8 md:px-8">
            {components.map(component =>
              match(component)
                .with(
                  { __typename: 'ContentfulTwoColumnSection' },
                  ({ rightSide, leftSide }) => (
                    <div className="flex flex-wrap">
                      <div className="paragraph w-full px-3 lg:w-1/2 lg:px-7">
                        {leftSide.content.map((content, index) =>
                          match(content)
                            .with(
                              { __typename: 'ContentfulSmallText' },
                              ({ text }) => (
                                <ContentfulRichText
                                  key={index}
                                  raw={text.raw}
                                />
                              )
                            )
                            .with(
                              { __typename: 'ContentfulFormV2' },
                              ({ button, fields }) => (
                                <EmbeddedPartnerForm
                                  button={button}
                                  fields={fields}
                                  partnerName={partnerName}
                                />
                              )
                            )
                            .exhaustive()
                        )}
                      </div>
                      <div className="w-full px-3 lg:w-1/2 lg:px-7">
                        <EmbeddedPartnerPackage {...rightSide} />
                      </div>
                    </div>
                  )
                )
                .with(
                  { __typename: 'ContentfulCarousel' },
                  ({ items, title, __typename }) => (
                    <FloorPlanCarouselWrapper
                      {...{ items, title, __typename }}
                    />
                  )
                )
                .with(
                  { __typename: 'ContentfulLayoutReference' },
                  ({ referenceId }) =>
                    match(referenceId)
                      .with('Monitoring', () => <MonitoringWrapper />)
                      .otherwise(() => null)
                )
                .with({ __typename: 'ContentfulSmallText' }, ({ text }) => (
                  <ContentfulRichText raw={text.raw} />
                ))
                .exhaustive()
            )}
          </div>
        </div>

        {footer ? <Footer data={footer} type={footer.type} /> : null}
      </PriceProvider>
    </TrackingProvider>
  )
}

export const query = graphql`
  query embeddedPartnerPageTemplate($id: String) {
    contentfulEmbeddedPartnerPage(id: { eq: $id }) {
      pageTitle
      partnerName
      seo {
        metaKeywords
        metaTitle
        isNoindex: noIndex
        isNofollow: noFollow
        canonicalLink
        metaDescription {
          metaDescription
        }
      }
      layout {
        header {
          ...embeddedPartnerHeader
        }
        footer {
          ...partnersFooter
        }
        components {
          ... on ContentfulTwoColumnSection {
            __typename
            rightSide {
              ... on ContentfulContentCollection {
                content {
                  ... on ContentfulCarousel {
                    __typename
                    items {
                      image {
                        description
                        url
                        originalWidth: width
                        originalHeight: height
                      }
                    }
                  }
                  ... on ContentfulSmallText {
                    text {
                      raw
                      references {
                        ... on ContentfulPlaceholder {
                          __typename
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
            leftSide {
              ... on ContentfulContentCollection {
                content {
                  ... on ContentfulSmallText {
                    text {
                      raw
                      references {
                        ... on ContentfulPlaceholder {
                          __typename
                          type
                        }
                      }
                    }
                  }
                  ...embeddedPartnerForm
                }
              }
            }
          }
          ... on ContentfulCarousel {
            title
            __typename
            items {
              headline
              description {
                raw
              }
              image {
                description
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on ContentfulLayoutReference {
            __typename
            referenceId
          }
          ... on ContentfulSmallText {
            __typename
            text {
              raw
            }
          }
        }
      }
    }
  }
`
