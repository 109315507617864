import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { GatsbyImage } from '@ecomm/shared-components'
import { useTrackDownloadAppClick } from '@ecomm/tracking'
import React from 'react'

import { Download } from '../../hooks/useConfirmationPageQuery'

interface Props {
  readonly content: Download
  readonly appleQR: GatsbyImageSchema
  readonly googleQR: GatsbyImageSchema
  readonly appStore: GatsbyImageSchema
  readonly googlePlay: GatsbyImageSchema
}

export enum Stores {
  apple,
  google
}

function DownloadApp({
  content,
  appleQR,
  googleQR,
  googlePlay,
  appStore
}: Props) {
  const trackDownload = useTrackDownloadAppClick()

  const qrImage = [appleQR, googleQR]
  const storeIcons = [appStore, googlePlay]

  const handleLinkClick = (store: Stores) => {
    const storeName = store === Stores.apple ? 'apple' : 'google'
    trackDownload(storeName)
  }

  return (
    <div
      className="flex w-full flex-col justify-center md:gap-8"
      data-component="DownloadSection"
      data-testid="ODMON-DownloadSection"
    >
      <div className="flex flex-col items-center">
        <h3 className="mb-4 mt-0 text-center text-2xl md:mb-6 md:text-3xl">
          {content.title}
        </h3>
        <p className="max-w-3xl text-center">{content.description}</p>
      </div>
      <div className="flex w-full flex-col md:hidden">
        <div className="align-center m-auto flex flex-col  items-center gap-5">
          <div
            data-component="AppleMobileLink"
            onClick={() => handleLinkClick(Stores.apple)}
          >
            <a
              href={`${content.stores[Stores.apple].link}`}
              rel="noreferrer"
              target="_blank"
            >
              <GatsbyImage
                className="w-[200px] cursor-pointer"
                image={storeIcons[Stores.apple]}
              />
            </a>
          </div>
          <div onClick={() => handleLinkClick(Stores.google)}>
            <a
              href={`${content.stores[Stores.google].link}`}
              rel="noreferrer"
              target="_blank"
            >
              <GatsbyImage
                className="w-[212px]"
                image={storeIcons[Stores.google]}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="hidden flex-col items-center justify-center md:flex md:gap-4 lg:flex-row lg:gap-12">
        {[Stores.apple, Stores.google].map(store => (
          <div
            className="bg-neutral-light-50 flex max-w-lg flex-row items-center gap-6 rounded-xl p-2 md:flex-row md:gap-8 md:px-10 md:py-8 lg:max-w-none lg:px-4 lg:py-6"
            data-component="storeQR"
            key={store}
          >
            <GatsbyImage className="h-52 w-52" image={qrImage[store]} />
            <div className="flex flex-col">
              <h4 className="text-center text-2xl font-medium">
                {content.stores[store].title}
              </h4>
              <p className="text-center">{content.stores[store].divider}</p>
              <div onClick={() => handleLinkClick(store)}>
                <a
                  href={`${content.stores[store].link}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <GatsbyImage image={storeIcons[store]} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DownloadApp
