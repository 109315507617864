import { voidFn } from '@simplisafe/ewok'
import { createContext, Dispatch, useContext, useReducer } from 'react'

import {
  initialState,
  QuizActions,
  QuizReducer,
  QuizState
} from './QuizReducer'

const QuizStateContext = createContext<QuizState>(initialState)

const QuizDispatchContext = createContext<Dispatch<QuizActions>>(voidFn)

interface QuizProviderProps {
  readonly children: React.ReactNode
}

export function QuizProvider({ children }: QuizProviderProps) {
  const [state, dispatch] = useReducer(QuizReducer, initialState)

  return (
    <QuizStateContext.Provider value={state}>
      <QuizDispatchContext.Provider value={dispatch}>
        {children}
      </QuizDispatchContext.Provider>
    </QuizStateContext.Provider>
  )
}

export function useQuizState() {
  return useContext(QuizStateContext)
}

export function useQuizDispatch() {
  return useContext(QuizDispatchContext)
}
