import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'

export type HeroBannerProps = {
  readonly image: GatsbyImageSchema
  readonly imageMobile: GatsbyImageSchema
  readonly title: string
}

export function HeroBanner({ image, imageMobile, title }: HeroBannerProps) {
  return (
    <div data-testid="ODMON-HeroBanner">
      <h2 className="h2 m-0 text-center">{title}</h2>
      <div className="mt-8">
        <div className="hidden md:block">
          <GatsbyImage className="rounded-2xl" image={image} />
        </div>
        <div className="md:hidden">
          <GatsbyImage
            className="flex aspect-square w-full rounded-xl"
            image={imageMobile}
          />
        </div>
      </div>
    </div>
  )
}
