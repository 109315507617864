import { Header } from '@ecomm/header-redesigned'
import { BelowTheFold } from '@ecomm/lander'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  CountryRedirectModal,
  PopupQuoteWizard
} from '@ecomm/shared-components'
import { Footer } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { Locale } from '@ecomm/utils'
import { graphql, PageProps } from 'gatsby'
import React from 'react'

import MediaPageBanner from '../../components/MediaPageBanner'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import { useMediaPageQuery } from '../../hooks/useMediaPageQuery'
import { useMediaPageFragment } from './useMediaPageFragment'

export type PageContext = {
  readonly locale: Locale
}

export type Props<T> = Pick<
  PageProps<T, PageContext, unknown, Response>,
  'data' | 'pageContext'
>

export default function MediaPageTemplate<T>({ data }: Props<T>) {
  const { heroText, mediaPageTemplate, pageTitle } =
    //@ts-expect-error
    useMediaPageFragment(data)

  const { defaultHeroText, defaultHeroImage, popupWizard } = mediaPageTemplate

  //heroBanner is optional but defaultHeroBanner not. So, if heroBanner exists, it will be prioritized
  const bannerText = heroText || defaultHeroText

  const headerData = useHeaderRedesignQuery()
  const bodyContent = useMediaPageQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <PromoBannerWrapper
        experimentData={promoBannerExperiment}
        type="standard"
      />
      <Header {...headerData} />
      <div className="prose relative z-0 w-full whitespace-pre-line md:prose-md lg:prose-lg">
        <div className="mx-auto mb-16 flex max-w-8xl flex-col gap-16 px-4 pt-8 md:px-8">
          <MediaPageBanner image={defaultHeroImage} text={bannerText} />
          <BelowTheFold data={bodyContent} />
          <CountryRedirectModal />
        </div>
      </div>
      {mediaPageTemplate.footer ? (
        <Footer data={mediaPageTemplate.footer} type="Full" />
      ) : null}
      <PopupQuoteWizard data={{ ...popupWizard }} />
    </TrackingProvider>
  )
}

export const query = graphql`
  query MediaPageTemplate($id: String) {
    contentfulMediaPageV2(id: { eq: $id }) {
      id
      contentful_id
      title
      pageTitle
      heroText {
        raw
      }
      mediaSource
      url
      mediaPageTemplate {
        ...mediaPageTemplateFragment
        ...mediaPageTemplateFragmentVariations
      }
    }
  }
`
