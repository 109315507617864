import { benefitsTextSchema, type BenefitsText } from '@ecomm/city-state-data'

export const benefitsMockText = benefitsTextSchema.parse({
  text: {
    json: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'heading-2',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Benefits of SimpliSafe® Security Systems in {{location}}',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'heading-3',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Half the cost of traditional providers',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Professional monitoring for less than $1 a day with no long-term contracts or hidden fees.',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'heading-3',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Powered by Fast Protect™ Technology',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Advanced technology helps our live, 24/7 monitoring agents quickly verify security threats for priority dispatch and faster police response. *Requires Interactive monitoring plan with video verification',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'heading-3',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Participating 911 centers get critical alarm data instantly, enabled by RapidSOS',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Our integration with RapidSOS technology means our 24/7 monitoring agents can send critical household data instantaneously to participating 911 centers so responders can act more quickly and accurately.',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'heading-3',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Prepared for the unexpected',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Lose power? Lose Wi-Fi? SimpliSafe is ready with battery back up and cellular connectivity.',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'heading-3',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Smart home compatible',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Use your system with Alexa, Google Assistant, August Locks, and Apple Watch.',
              marks: [],
              data: {}
            }
          ]
        }
      ]
    }
  }
})

export const mockHeadingWithSuperscript = benefitsTextSchema.parse({
  text: {
    json: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'heading-2',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Benefits of SimpliSafe',
              marks: [],
              data: {}
            },
            {
              nodeType: 'text',
              value: '®',
              marks: [
                {
                  type: 'superscript'
                }
              ],
              data: {}
            },
            {
              nodeType: 'text',
              value: ' Security Systems in {{location}}',
              marks: [],
              data: {}
            }
          ]
        }
      ]
    }
  }
})
