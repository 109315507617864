import React, { useEffect, useRef } from 'react'

import { useQuizState } from '../QuizContext'
import { useGetQuizCurrentStep } from './useGetQuizCurrentStep'

export function QuizCurrentStep() {
  const CurrentStepComponent = useGetQuizCurrentStep()
  const { currentStep } = useQuizState()
  const contentRef = useRef<HTMLDivElement>(null)

  // Scroll to top when step changes
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [currentStep])

  return (
    <div
      className="z-40 max-h-[75%] grow overflow-x-hidden p-5 pb-20"
      ref={contentRef}
    >
      {CurrentStepComponent}
    </div>
  )
}
