import React from 'react'

import { HardwarePriceImage } from '../../HardwarePriceImage'
import { usePurchaseOutdoorCameraQuery } from './usePurchaseOutdoorCameraQuery'

interface Props {
  readonly userAmount: number
  readonly selectedAmount: number
  readonly handleOptionChange: (a: number) => void
}

export function PurchaseCameraScreen({
  userAmount,
  selectedAmount,
  handleOptionChange
}: Props) {
  const { image, ...content } = usePurchaseOutdoorCameraQuery()

  const options =
    userAmount === 0 ? content.options.slice(1) : content.options.slice(0, 2)

  return (
    <div data-testid="ODMON-Quiz-PurchaseCameraScreen">
      <HardwarePriceImage
        currentPrice={content.currentPrice}
        image={image}
        originalPrice={content.originalPrice}
        pricePosition="right"
      />
      <h1 className="font-default mb-6 mt-4 text-center text-xl">
        {content.title}
      </h1>
      <section className="mb-4">
        <h2 className="font-default mb-2 text-center text-base">
          {content.subtitle}
        </h2>
      </section>
      <section>
        <p className="text-center text-sm">{content.paragraph}</p>
      </section>
      <section className="mb-6 mt-4 flex flex-col items-center text-center">
        <h2 className="font-default mb-4 text-base">{content.question}</h2>
        {options.map(option => (
          <label
            className="mb-4 inline-flex w-36 items-center"
            key={option.value}
          >
            <input
              checked={selectedAmount === option.value}
              className="border-neutral-medium-100 h-6 w-6 cursor-pointer rounded-full checked:bg-[#006FEE]"
              onChange={() => handleOptionChange(option.value)}
              type="radio"
              value={option.value}
            />
            <span className="ml-2">{option.text}</span>
          </label>
        ))}
      </section>
    </div>
  )
}
