import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { OdmonChoiceFlowEvent } from '@ecomm/tracking'

import { useQuizState } from '../QuizContext'
import { useCreateQuizTrackingData } from '../useCreateQuizTrackingData'

interface Props {
  readonly onClose: () => void
}

export function QuizHeader({ onClose }: Props) {
  const { currentStep } = useQuizState()
  const trackingData = useCreateQuizTrackingData()

  const onCloseModal = () => {
    const trackEvent = trackingData[currentStep](
      OdmonChoiceFlowEvent.CLOSE_MODAL
    )

    trackEvent()
    onClose()
  }

  return (
    <div
      className="bg-neutral-black flex h-[65px] flex-row items-center justify-between p-4 align-middle"
      data-testid="ODMON-Quiz-Header"
    >
      <SimpliSafeLogo height={24} width={118} />
      <button
        className="cursor-pointer border-0 bg-transparent text-white focus:outline-none"
        data-component="close-button"
        onClick={onCloseModal}
      >
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6l12 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </svg>
        <span className="sr-only">Close quiz modal</span>
      </button>
    </div>
  )
}
