import {
  benefitsTextSchema,
  CITY_STATE_TEMPLATE_REGION_CONTENT_QUERY
} from '@ecomm/city-state-data'
import { useContentful } from '@ecomm/shared-apollo'
import { graphql, useStaticQuery } from 'gatsby'
import { useMemo } from 'react'

import { benefitsSchema } from './schema'

export const useBenefitsQuery = () => {
  return benefitsSchema.parse(
    useStaticQuery(graphql`
      query BenefitsQuery {
        images: allContentfulAsset(
          filter: {
            contentful_id: {
              in: ["4ybJ9ftX9ynMSD5tnEiAcV", "7e1hT6DJeh1F38xbRkYxY4"]
            }
          }
        ) {
          nodes {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        text: contentfulSmallText(
          contentful_id: { eq: "6ZE9ykO3UYWm1VOJqyydUM" }
        ) {
          text {
            raw
          }
        }
      }
    `)
  )
}

export const useBenefitsTextQuery = (region: string) => {
  const { data: rawApolloData } = useContentful(
    CITY_STATE_TEMPLATE_REGION_CONTENT_QUERY,
    {
      regionContentId: region
    }
  )

  return useMemo(
    () => benefitsTextSchema.parse(rawApolloData?.text),
    [rawApolloData]
  )
}
