import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

export interface Content {
  readonly welcome: Welcome
  readonly download: Download
  readonly reminders: Reminder
  readonly support: Support
}

export interface Welcome {
  readonly title: string
  readonly description: readonly string[]
  readonly disclaimer: string
}

export interface Download {
  readonly title: string
  readonly description: string
  readonly stores: readonly Store[]
}

export interface Reminder {
  readonly title: string
  readonly items: readonly string[]
}

export interface Support {
  readonly title: string
  readonly description: string
  readonly phone: string
}

interface Store {
  readonly title: string
  readonly divider: string
  readonly link: string
}

const useConfirmationPageQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONConfirmationPage {
      content: contentfulJson(contentful_id: { eq: "5zuEwt9g3X2AZ55iK0rQLI" }) {
        ...json
      }
      monitoringAgent: contentfulAsset(
        contentful_id: { eq: "5YcwkF8tM9VMFfrspIomZg" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      appleQR: contentfulAsset(
        contentful_id: { eq: "7lChpM4GE20CcKDjImAEDX" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      googleQR: contentfulAsset(
        contentful_id: { eq: "5RLz3lqENtnzSo6p2LbtuD" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      googlePlay: contentfulAsset(
        contentful_id: { eq: "1lpvuD6Dr3YfI0klJZGoF5" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      appStore: contentfulAsset(
        contentful_id: { eq: "1LZZhX8Tixah9lRPDQeWZH" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
    }
  `)

  const textContent = parseJSONDataFromContentful<Content>(
    staticQuery,
    'content'
  )

  return {
    content: textContent,
    images: {
      monitoringAgent: staticQuery.monitoringAgent,
      appleQR: staticQuery.appleQR,
      googleQR: staticQuery.googleQR,
      appStore: staticQuery.appStore,
      googlePlay: staticQuery.googlePlay
    }
  }
}

export default useConfirmationPageQuery
