import { IOAddToCart, IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart'
import {
  IODeleteCart,
  LOCAL_STORAGE_CARTID,
  setCart
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { pipe } from 'fp-ts/function'
import { localStorage } from '@simplisafe/ewok'
import { None } from '@simplisafe/ewok'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  CUSTOMER_GROUP_ODMON,
  SKU_AUDIO_ENHANCER,
  SKU_BOOKLET,
  SKU_CABLE,
  SKU_CAMERA,
  SKU_MOUNT
} from '../utils/constants'

const { remove } = localStorage

export const useBuildCart = (onFailure: () => void, onSuccess: () => void) => {
  const dispatch = useDispatch()

  const getCoupon = (purchaseCamCount: number, purchaseKitCount: number) =>
    `ODMEA${purchaseCamCount}C${purchaseKitCount}K`

  /**
   * Add our customer group to the current cart.
   * On success, move along.
   */
  const addCustomerGroupToCart = useCallback(() => {
    dispatch(
      IOUpdateCart(
        [
          {
            action: 'setCustomerGroup',
            customerGroup: CUSTOMER_GROUP_ODMON,
            userId: 'not-required'
          }
        ],
        () => onFailure && onFailure(),
        () => onSuccess && onSuccess()
      )
    )
  }, [dispatch, onFailure, onSuccess])

  /**
   * Add products to the cart (will create if not present).
   * On success, add customer group.
   */
  const createCart = useCallback(
    (purchaseCamCount: number, purchaseKitCount: number) => {
      const kitProducts = [
        {
          quantity: purchaseKitCount,
          sku: SKU_AUDIO_ENHANCER
        },
        {
          quantity: purchaseKitCount,
          sku: SKU_MOUNT
        },
        {
          quantity: purchaseKitCount,
          sku: SKU_CABLE
        },
        {
          quantity: 1,
          sku: SKU_BOOKLET
        }
      ]

      const addToCartProducts = purchaseCamCount
        ? [
            ...kitProducts,
            {
              quantity: purchaseCamCount,
              sku: SKU_CAMERA
            }
          ]
        : kitProducts

      dispatch(
        IOAddToCart(
          {
            products: addToCartProducts,
            discountCode: getCoupon(purchaseCamCount, purchaseKitCount)
          },
          () => onFailure && onFailure(),
          addCustomerGroupToCart
        )
      )
    },
    [addCustomerGroupToCart, dispatch, onFailure]
  )

  /**
   * IODeleteCart is funky -- does not execute unless cart is in Active state
   * This mimics what it would have done to clear things out on the local side
   */
  const deleteCartFailsafe = useCallback(() => {
    dispatch(setCart(None()))
    remove(LOCAL_STORAGE_CARTID)
  }, [dispatch])

  /**
   * Delete existing cart if present
   */
  const deleteCart = useCallback(() => {
    dispatch(IODeleteCart())
  }, [dispatch])

  /**
   * Actual program flow:
   *  delete cart
   *  add products to generate a new one
   *    now follows success handler callbacks to add
   *    customer group and navigate to checkout
   *
   * Trouble getting IODeleteCart to finish before creating a cart,
   * hence the delay
   */

  return useCallback(
    (purchaseCamCount: number, purchaseKitCount: number) => {
      pipe(deleteCart(), () =>
        setTimeout(() => {
          deleteCartFailsafe()
          createCart(purchaseCamCount, purchaseKitCount)
        }, 1500)
      )
    },
    [createCart, deleteCart, deleteCartFailsafe]
  )
}
