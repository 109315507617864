import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const purchaseCameraScreenData = z.object({
  title: z.string(),
  subtitle: z.string(),
  paragraph: z.string(),
  question: z.string(),
  options: z.array(z.object({ value: z.number(), text: z.string() })),
  currentPrice: z.string(),
  originalPrice: z.string(),
  image: gatsbyImageSchema
})

export type PurchaseCameraScreenData = TypeOf<typeof purchaseCameraScreenData>
