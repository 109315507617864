import { graphql, useStaticQuery } from 'gatsby'

const useFooterQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query CityStateTemplateQuery {
      footer: contentfulFooter(
        contentful_id: { eq: "2SCKVa13yRuLSU4GuooHlb" }
      ) {
        ...cityStateFooter
      }
    }
  `)

  return staticQuery.footer
}

export default useFooterQuery
