import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'
import {
  ExistingOutdoorCamerasData,
  existingOutdoorCamerasData
} from './schema'

export const useExistingOutdoorCamerasQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONExistingOutdoorCamerasQuery {
      content: contentfulJson(contentful_id: { eq: "7qEMO0meFAXHjY9zwv36aN" }) {
        ...json
      }
      asset: contentfulAsset(contentful_id: { eq: "73a9K6XnIlKk3rtowy0AEc" }) {
        gatsbyImageData(placeholder: BLURRED)
        description
      }
    }
  `)

  const content = parseJSONDataFromContentful<ExistingOutdoorCamerasData>(
    staticQuery,
    'content'
  )

  const asset = staticQuery.asset

  return existingOutdoorCamerasData.parse({ ...content, asset })
}
