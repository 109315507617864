import { useMemo } from 'react'

import { useQuizState } from '../QuizContext'
import { useGetQuizSteps } from './useGetQuizSteps'

export const useGetQuizCurrentStep = () => {
  const state = useQuizState()
  const steps = useGetQuizSteps()

  return useMemo(() => steps[state.currentStep], [steps, state.currentStep])
}
