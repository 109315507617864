import { graphql, useStaticQuery } from 'gatsby'

import { HeroBannerProps } from '../components/HeroBanner'
import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

const useHeroBannerQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONHeroBannerQuery {
      content: contentfulJson(contentful_id: { eq: "2ow8voVoyqmboSYOLVB3Fo" }) {
        ...json
      }
      image: contentfulAsset(contentful_id: { eq: "1VHaUcLI2vBS0yCyiJFnWA" }) {
        gatsbyImageData(placeholder: BLURRED)
      }
      imageMobile: contentfulAsset(
        contentful_id: { eq: "6kNkLXSldszxHubIQHYpgP" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  `)

  const contentData = parseJSONDataFromContentful<
    Omit<HeroBannerProps, 'image'>
  >(staticQuery, 'content')

  return {
    title: contentData.title,
    image: staticQuery.image,
    imageMobile: staticQuery.imageMobile
  }
}

export default useHeroBannerQuery
