import { TypeOf, z } from '@simplisafe/ewok'

export const introScreenData = z.object({
  title: z.string(),
  sections: z.array(
    z.object({
      title: z.string(),
      items: z.array(z.string())
    })
  ),
  notes: z.array(z.string())
})

export type IntroScreenData = TypeOf<typeof introScreenData>
