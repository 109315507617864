import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { useExistingOutdoorCamerasQuery } from './useExistingOutdoorCamerasQuery'

export interface ExistingOutdoorCamerasProps {
  readonly handleOptionChange: (option: number) => void
  readonly selectedOption: number
}

export function ExistingOutdoorCameras({
  selectedOption,
  handleOptionChange
}: ExistingOutdoorCamerasProps) {
  const data = useExistingOutdoorCamerasQuery()

  return (
    <div data-testid="ODMON-Quiz-ExistingOutdoorCameras">
      <div className="flex items-center justify-center">
        <div className="flex h-32 items-center justify-center rounded-lg">
          <GatsbyImage
            alt="Outdoor Camera"
            className="h-full w-full rounded-lg object-cover"
            image={data.asset.gatsbyImageData}
          />
        </div>
      </div>
      <h1 className="font-default mb-6 mt-4 text-center text-2xl">
        {data.title}
      </h1>
      <div className="mt-4 flex flex-col items-center text-left">
        {data.options.map((option, index) => (
          <label
            className="mb-6 inline-flex w-52 items-center"
            key={`${option.label}-${index}`}
          >
            <input
              checked={selectedOption === option.value}
              className="border-neutral-medium-100 h-6 w-6 cursor-pointer rounded-full checked:bg-[#006FEE]"
              onChange={() => handleOptionChange(option.value)}
              type="radio"
              value={option.value}
            />
            <span className="ml-2">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  )
}
