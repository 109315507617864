import { BestSystems, BestSystemsProps } from '../BestSystems'
import {
  DropdownRedirector,
  DropdownRedirectorProps
} from '../DropdownRedirector'
import { Map, MapProps } from './Map'

export type LocationMapAndDropdownProps = {
  readonly bestSystemsData: BestSystemsProps
  readonly dropdownRedirectorData?: DropdownRedirectorProps | null
  readonly mapData?: MapProps | null
}

export function LocationMapAndDropdown({
  bestSystemsData,
  dropdownRedirectorData = null,
  mapData = null
}: LocationMapAndDropdownProps) {
  return (
    <div
      className="bg-neutral-light-50"
      data-component="LocationMapAndDropdown"
    >
      <div className="mx-auto max-w-7xl px-6 py-4 md:px-10 lg:py-5">
        <BestSystems {...bestSystemsData} />

        {mapData ? (
          <div className="mt-10 lg:mt-16">
            <Map {...mapData} />
          </div>
        ) : null}
      </div>

      {dropdownRedirectorData ? (
        <div className="prose-h2:text-2xl lg:prose-h2:text-3xl -my-4">
          <DropdownRedirector {...dropdownRedirectorData} />
        </div>
      ) : null}
    </div>
  )
}
