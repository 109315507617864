import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { ODMONQuizScreens, TrackingData } from '../types/tracking'

export enum OdmonChoiceFlowEvent {
  BACK_CLICK = 'choice-flow-back-click',
  NEXT_CLICK = 'choice-flow-next-click',
  CLOSE_MODAL = 'choice-flow-close-modal',
  ADD_TO_CART = 'odmon-add-to-cart'
}

export const useTrackOdmonModalChoiceFlow = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (
      page: ODMONQuizScreens,
      event: OdmonChoiceFlowEvent,
      selection: number | string
    ) => {
      trackEvent({
        eventCategory: 'quiz',
        eventAction: 'click',
        selection,
        event,
        page
      })
    },
    [trackEvent]
  )
}
