import { pipe } from 'fp-ts/lib/function'
import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'
import { PurchasePlanData, purchasePlanData } from './schema'

export const usePurchasePlanQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONPurchasePlanScreenQuery {
      content: contentfulJson(contentful_id: { eq: "5i41KAWqmUEIhsFaekTLZ5" }) {
        ...json
      }
      plan1: contentfulAsset(contentful_id: { eq: "4WIkGImndTRhEvVAfQKrcE" }) {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
      plan2: contentfulAsset(contentful_id: { eq: "2OUjxIV4w4gBJcoeWX4Dxe" }) {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
    }
  `)

  const content = pipe(
    parseJSONDataFromContentful<PurchasePlanData>(staticQuery, 'content'),
    content => ({
      ...content,
      plans: content.plans.map(plan => ({
        ...plan,
        asset: staticQuery[plan.id]
      }))
    })
  )

  return purchasePlanData.parse(content)
}
