import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'
import { IntroScreenData, introScreenData } from './schema'

export const useIntroScreenQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONIntroScreenQuery {
      content: contentfulJson(contentful_id: { eq: "5KlvfSozESgluIu9CPc0if" }) {
        ...json
      }
    }
  `)

  const content = parseJSONDataFromContentful<IntroScreenData>(
    staticQuery,
    'content'
  )

  return introScreenData.parse(content)
}
