import { Footer } from '@ecomm/shared-components'
import { useTrackOdmonConfirmation } from '@ecomm/tracking'
import { get as sessionStorageGet } from '@ecomm/utils'
import React, { useEffect } from 'react'

import DownloadApp from '../components/Confirmation/DownloadApp'
import MonitoringAgent from '../components/Confirmation/MonitoringAgent'
import Reminder from '../components/Confirmation/Reminder'
import Support from '../components/Confirmation/Support'
import { Header } from '../components/Header'
import useConfirmationPageQuery from '../hooks/useConfirmationPageQuery'
import useFooterQuery from '../hooks/useFooterQuery'

function PaymentConfirmationTemplate() {
  const { content, images } = useConfirmationPageQuery()
  const trackOdmonConfirmation = useTrackOdmonConfirmation()
  const footerData = useFooterQuery()

  const { welcome, download, reminders, support } = content

  useEffect(() => {
    const cameras = sessionStorageGet('odmon-cameras')
    const plan = sessionStorageGet('odmon-plan')
    const kit = sessionStorageGet('odmon-kit')

    trackOdmonConfirmation(parseInt(cameras), parseInt(kit), plan)
  }, [trackOdmonConfirmation])

  return (
    <div
      className="prose md:prose-md lg:prose-lg whitespace-pre-line"
      data-testid="ODMON-PaymentConfirmation"
    >
      <Header displayButton={false} />
      <main className="max-w-8xl m-auto mb-12 mt-6 flex flex-col gap-6 px-5 py-4 md:gap-12 md:px-8 lg:gap-14 lg:px-36">
        <MonitoringAgent content={welcome} image={images.monitoringAgent} />
        <DownloadApp content={download} {...images} />
        <Reminder content={reminders} />
        <Support content={support} />
      </main>
      <Footer data={footerData} showCountrySelector={false} />
    </div>
  )
}

export default PaymentConfirmationTemplate
