import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const existingOutdoorCamerasData = z.object({
  title: z.string(),
  options: z.array(
    z.object({
      label: z.string(),
      value: z.number()
    })
  ),
  asset: gatsbyImageSchema
})

export type ExistingOutdoorCamerasData = TypeOf<
  typeof existingOutdoorCamerasData
>
