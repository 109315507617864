import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const purchasePlanData = z.object({
  title: z.string(),
  description: z.string(),
  plans: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      description: z.string(),
      price: z.object({
        label: z.string(),
        value: z.number()
      }),
      priceNote: z.string(),
      asset: gatsbyImageSchema
    })
  ),
  notes: z.array(z.string())
})

export type PurchasePlanData = TypeOf<typeof purchasePlanData>
