import { QuizButtons } from './QuizButtons'
import { QuizProvider } from './QuizContext'
import { QuizCurrentStep } from './QuizCurrentStep'
import { QuizHeader } from './QuizHeader'
import { QuizModal } from './QuizModal'

export interface QuizWrapperProps {
  readonly isModalOpen: boolean
  readonly onCloseModal: () => void
}

export function QuizWrapper({ isModalOpen, onCloseModal }: QuizWrapperProps) {
  return (
    <QuizProvider>
      <QuizModal isOpen={isModalOpen} onClose={onCloseModal}>
        <QuizHeader onClose={onCloseModal} />
        <QuizCurrentStep />
        <QuizButtons />
      </QuizModal>
    </QuizProvider>
  )
}
