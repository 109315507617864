import React, { useMemo } from 'react'

import { useQuizDispatch, useQuizState } from '../QuizContext'
import { QuizActionTypes, QuizSteps } from '../QuizReducer'
import { ExistingOutdoorCameras } from '../QuizSteps/ExistingOutdoorCameras'
import { IntroScreen } from '../QuizSteps/IntroScreen'
import { PurchaseCameraScreen } from '../QuizSteps/PurchaseCameraScreen'
import { PurchaseKits } from '../QuizSteps/PurchaseKits'
import { PurchasePlan } from '../QuizSteps/PurchasePlan'
import { ReviewScreen } from '../QuizSteps/ReviewScreen'

export const useGetQuizSteps = () => {
  const state = useQuizState()
  const dispatch = useQuizDispatch()

  const createAction = (type: QuizActionTypes) => (payload: number) =>
    dispatch({ type, payload })

  const setPurchaseOutdoorCameras = createAction(
    QuizActionTypes.SET_PURCHASE_CAMERAS
  )
  const setExistingOutdoorCameras = createAction(
    QuizActionTypes.SET_EXISTING_CAMERAS
  )
  const setPurchaseKit = createAction(QuizActionTypes.SET_PURCHASE_KIT)
  const setPurchasePlan = createAction(QuizActionTypes.SET_PURCHASE_PLAN)

  return useMemo(
    () => [
      <IntroScreen key={QuizSteps.INTRO} />,
      <ExistingOutdoorCameras
        handleOptionChange={setExistingOutdoorCameras}
        key={QuizSteps.EXISTING_OUTDOOR_CAMERAS}
        selectedOption={state.existingOutdoorCameras}
      />,
      <PurchaseCameraScreen
        handleOptionChange={setPurchaseOutdoorCameras}
        key={QuizSteps.PURCHASE_CAMERA}
        selectedAmount={state.purchaseOutdoorCameras}
        userAmount={state.existingOutdoorCameras}
      />,
      <PurchaseKits
        handleOptionChange={setPurchaseKit}
        key={QuizSteps.PURCHASE_KITS}
        selectedOption={state.purchaseKit}
        userAmount={state.existingOutdoorCameras}
        userOutdoorCameras={state.purchaseOutdoorCameras}
      />,
      <PurchasePlan
        handleOptionChange={setPurchasePlan}
        key={QuizSteps.PURCHASE_PLAN}
        selectedOption={state.purchasePlan}
      />,
      <ReviewScreen
        key={QuizSteps.REVIEW}
        purchaseKit={state.purchaseKit}
        purchaseOutdoorCameras={state.purchaseOutdoorCameras}
        purchasePlan={state.purchasePlan}
      />
    ],
    [
      state.purchaseOutdoorCameras,
      state.purchaseKit,
      state.purchasePlan,
      state.existingOutdoorCameras,
      setPurchaseOutdoorCameras,
      setExistingOutdoorCameras,
      setPurchaseKit,
      setPurchasePlan
    ]
  )
}
