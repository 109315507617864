import { submitEmailFeedback } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import { ContentfulRichText } from '@ecomm/shared-components'
import { zodResolver } from '@hookform/resolvers/zod'
import { match } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { ChangeEvent, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { EmailFeedbackProps, emailFeedbackSchema } from './schema'

export default function EmailFeedbackForm({
  button,
  form,
  note,
  submittedMessage,
  positiveFeedback,
  campaignId,
  userId,
  successMessage
}: EmailFeedbackProps) {
  const [charactersUsed, setCharactersUsed] = useState(0)
  const [formStatus, setFormStatus] = useState({
    isLoading: false,
    isSubmitted: false,
    error: false
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: zodResolver(emailFeedbackSchema) })

  const onSubmit = async (submitFormValues: FieldValues) => {
    const fields = {
      userComment: submitFormValues['userComment'],
      campaignId,
      positiveFeedback,
      userId: Number(userId) ?? 0
    }

    setFormStatus(prev => ({ ...prev, isLoading: true }))

    pipe(
      await submitEmailFeedback(fields),
      match(
        (e: Error) => {
          setFormStatus({
            isSubmitted: true,
            isLoading: false,
            error: true
          })
          logError(e)
        },
        _ => {
          setFormStatus({
            isSubmitted: true,
            error: false,
            isLoading: false
          })
        }
      )
    )
  }

  const changeQuantityOfCharacters = (
    ev: ChangeEvent<{ readonly value: string }>
  ) => {
    setCharactersUsed(ev.target.value.length)
  }

  return !formStatus.isSubmitted || formStatus.error ? (
    <div className="flex flex-col text-center items-center">
      <h1 className="text-heading-3-size mb-4">{submittedMessage}</h1>
      <ContentfulRichText {...note} />
      <form
        className="w-full items-center flex flex-col gap-4 md:gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        {form.map(input => (
          <div
            className="flex w-full flex-col items-start"
            key={`input-${input.inputName}`}
          >
            <textarea
              {...register(input.inputName)}
              className="placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base border-neutral-medium-100 border border-solid h-44 w-full p-4"
              id={input.inputName}
              maxLength={500}
              onChange={changeQuantityOfCharacters}
              placeholder={input.placeholder}
            />
            <div className="mt-2 w-full flex flex-col md:flex-row md:items-center md:justify-between">
              <span>Max characters: 500</span>
              <span className={`${charactersUsed === 0 ? 'invisible' : ''}`}>
                Remaining characters: {500 - charactersUsed}
              </span>
            </div>
            {!!errors[input.inputName] ? (
              <span className="text-complementary-red-100">
                {errors[input.inputName]?.message?.toString()}
              </span>
            ) : null}
          </div>
        ))}
        <button
          className={`btn btn-solid-primary flex items-center justify-center relative ${formStatus.isLoading ? 'pointer-events-none' : ''}`}
          type="submit"
        >
          <span className={formStatus.isLoading ? 'invisible' : ''}>
            {button.text}
          </span>
          <span
            className={`${!formStatus.isLoading ? 'invisible' : 'visible'} absolute border-white border-b-none h-8 w-8 animate-spin rounded-full border-4 border-solid border-b-transparent`}
          />
        </button>
      </form>
    </div>
  ) : (
    <div className="text-center flex h-96 items-center justify-center w-full">
      <ContentfulRichText {...successMessage} />
    </div>
  )
}
