import React from 'react'

import { Support as SupportType } from '../../hooks/useConfirmationPageQuery'

interface Props {
  readonly content: SupportType
}
function Support({ content }: Props) {
  return (
    <div
      className="flex flex-col items-center"
      data-component="SupportSection"
      data-testid="ODMON-SupportSection"
    >
      <h3 className="font-arizona mb-4 mt-0 text-center text-2xl md:text-3xl">
        {content.title}
      </h3>
      <p className="text-center">{content.description}</p>
      <p className="text-center">{content.phone}</p>
    </div>
  )
}

export default Support
