import {
  AuthenticationComponent,
  AuthenticationType
} from '@ecomm/auth-components'
import { Footer, UserReviews } from '@ecomm/shared-components'
import { TrackingData, TrackingProvider } from '@ecomm/tracking'
import {
  TrackPlacement,
  useTrackingODMONEarlyAccessClick
} from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import { Banner } from '../components/Banner'
import { FAQs } from '../components/FAQs'
import { FeatureDetails } from '../components/FeatureDetails'
import { Header } from '../components/Header'
import { HeroBanner } from '../components/HeroBanner'
import { QuizWrapper } from '../components/QuizWrapper'
import { VideoSection } from '../components/VideoSection'
import useFaqsQuery from '../hooks/useFaqsQuery'
import useFeatureDetailsQuery from '../hooks/useFeatureDetailsQuery'
import useFooterQuery from '../hooks/useFooterQuery'
import useHeroBannerQuery from '../hooks/useHeroBannerQuery'
import usePageBannerQuery from '../hooks/usePageBannerQuery'
import { useTrackPreAuthVisit } from '../hooks/useTrackPreAuthVisit'
import useUserReviewsQuery from '../hooks/useUserReviewsQuery'
import useVideoSectionQuery from '../hooks/useVideoSectionQuery'

export default function BetaLanderTemplate() {
  const [open, setOpen] = useState(false)

  const bannerData = usePageBannerQuery()
  const featureDetailsData = useFeatureDetailsQuery()
  const videoSectionData = useVideoSectionQuery()
  const heroBannerData = useHeroBannerQuery()
  const reviewsData = useUserReviewsQuery()
  const faqsData = useFaqsQuery()
  const footerData = useFooterQuery()
  const trackClick = useTrackingODMONEarlyAccessClick()

  const onCloseModal = () => {
    setOpen(false)
  }

  const onOpenModal = (trackPlacement: TrackPlacement) => {
    setOpen(true)
    trackClick(trackPlacement)
  }

  const { trackEvent } = useTracking<TrackingData>()
  const trackPreAuthView = useTrackPreAuthVisit(trackEvent)
  useEffect(() => {
    trackPreAuthView()
  })

  const pageTitle = 'Outdoor Monitoring Early Access'
  return (
    <AuthenticationComponent language={'en-US'} type={AuthenticationType.odmon}>
      <TrackingProvider metaTitle={pageTitle}>
        <SEO
          isLandingPage={true}
          isNofollow={true}
          isNoindex={true}
          lang="en-US"
          metaDescription={pageTitle}
          metaTitle={pageTitle}
        />
        <div
          className="prose md:prose-md lg:prose-lg whitespace-pre-line"
          data-testid="ODMON-Template"
        >
          <QuizWrapper isModalOpen={open} onCloseModal={onCloseModal} />

          <Header onClick={() => onOpenModal('nav')} />
          <Banner {...bannerData} />
          <main className="max-w-8xl m-auto mb-12 flex flex-col gap-6 px-5 md:gap-12 md:px-8 lg:gap-14">
            <div className="mt-12">
              <VideoSection
                {...videoSectionData}
                onClick={() => onOpenModal('atf')}
              />
            </div>

            {featureDetailsData.sections.map((sections, index) => (
              <div
                className="mt-10 md:mt-[60px]"
                data-testid={`ODMON-FeatureDetails-${index}`}
                key={index}
              >
                <FeatureDetails {...sections} />
              </div>
            ))}

            <div className="mt-20">
              <HeroBanner {...heroBannerData} />
            </div>

            <div data-testid="ODMON-UserReviews">
              <UserReviews {...reviewsData} />
            </div>
            <FAQs {...faqsData} />
          </main>

          <Footer data={footerData} showCountrySelector={false} />
        </div>
      </TrackingProvider>
    </AuthenticationComponent>
  )
}
