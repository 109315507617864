import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import {
  contentfulRichTextSchema,
  footerSchema,
  guidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { addVariationSchema } from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'

export const mediaPageTemplateSchema = z.object({
  __typename: z.literal('ContentfulMediaPageTemplateV2'),
  contentful_id: z.string(),
  defaultPageTitle: z.string(),
  defaultHeroText: contentfulRichTextSchema,
  defaultHeroImage: gatsbyImageSchema,
  popupWizard: guidedSystemBuilderSchema,
  footer: footerSchema.optional()
})

export const mediaPageSchema = z.object({
  __typename: z.literal('ContentfulMediaPageV2'),
  contentful_id: z.string(),
  pageTitle: z.string(),
  heroText: contentfulRichTextSchema.nullish(),
  mediaSource: z.string().default(''),
  url: z.string(),
  mediaPageTemplate: addVariationSchema(mediaPageTemplateSchema)
})

export type MediaPageProps = TypeOf<typeof mediaPageSchema>
