import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackOdmonConfirmation() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (shields: number, kit: number, plan: string) => {
      trackEvent({
        shields,
        kit,
        plan,
        event: 'odmon-order-confirmation'
      })
    },
    [trackEvent]
  )
}
