import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import { get as sessionStorageGet } from '@ecomm/utils'
import React from 'react'

import { Welcome } from '../../hooks/useConfirmationPageQuery'

interface Props {
  readonly image: GatsbyImageSchema
  readonly content: Welcome
}

function MonitoringAgent({ content, image }: Props) {
  const orderId = sessionStorageGet('orderId')
  const userData = sessionStorageGet('userData')
  const monitoring = sessionStorageGet('selectedMonitoring')

  const transactionTotal = userData
    ? '$' + JSON.parse(userData).transactionTotal
    : ''

  const processDescription = (lineText: string) => {
    return lineText
      .replace('{{orderId}}', orderId)
      .replace('{{price}}', transactionTotal)
      .replace('{{monitoring}}', monitoring)
  }

  return (
    <div
      className="bg-neutral-light-50 flex flex-col items-center gap-6 rounded-xl px-4 py-6 md:flex-row md:gap-8 md:px-10 md:py-8"
      data-component="MonitoringAgentSection"
      data-testid="ODMON-MonitoringAgentSection"
    >
      <GatsbyImage className="w-40 md:w-56" image={image} />
      <div className="flex grow-0 flex-col">
        <h3 className="mb-4 mt-0 text-center text-2xl md:text-left md:text-3xl">
          {content.title}
        </h3>
        {content.description.map((text, index) => (
          <p className="text-center md:text-left" key={index}>
            {processDescription(text)}
          </p>
        ))}
        <small className="mt-4 text-center md:text-left">
          {content.disclaimer}
        </small>
      </div>
    </div>
  )
}

export default MonitoringAgent
