import { GatsbyImageSchema } from '@ecomm/shared-components'
import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../utils/parseJSONDataFromContentful'

export interface Content {
  readonly sections: readonly Section[]
}

interface Feature {
  readonly title: string
  readonly description: string
  readonly bullets: readonly string[]
  readonly image?: GatsbyImageSchema
}

interface Section {
  readonly title: string
  readonly features: readonly Feature[]
}

const useFeatureDetailsQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONEducationalQuery {
      content: contentfulJson(contentful_id: { eq: "bThvBhNNzcYiWZGEEvXYz" }) {
        ...json
      }
      advancedAI: contentfulAsset(
        contentful_id: { eq: "1znLuIrRbjaXaGlZLLwLft" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      privacyControl: contentfulAsset(
        contentful_id: { eq: "4mZLvMoNSYcNRlaRKVGsmc" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      friendlyFace: contentfulAsset(
        contentful_id: { eq: "5ytuDjCV0H2pWSDsIcZhD2" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      liveAgents: contentfulAsset(
        contentful_id: { eq: "2ySMQa0ezAoWOsymSuAPUU" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
    }
  `)

  const textContent = parseJSONDataFromContentful<Content>(
    staticQuery,
    'content'
  )

  const imageKeys = [
    ['advancedAI', 'liveAgents'],
    ['privacyControl', 'friendlyFace']
  ]

  const updatedSections = textContent.sections.map((section, i) => {
    const updatedFeatures = section.features.map((feature, j) => ({
      ...feature,
      image: staticQuery[imageKeys[i][j]]
    }))

    return {
      ...section,
      features: updatedFeatures
    }
  })

  const updatedContent = {
    ...textContent,
    sections: updatedSections
  }

  return updatedContent
}

export default useFeatureDetailsQuery
