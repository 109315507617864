import { NativeVideo } from '@ecomm/shared-components'

export type VideoSectionProps = {
  readonly title: string
  readonly text: string
  readonly button: {
    readonly text: string
  }
  readonly video: {
    readonly webmLink: string
    readonly mp4Link: string
    readonly posterImage: string
    readonly captionsSrc: string
  }
  readonly onClick: () => void
}

export function VideoSection({
  title,
  text,
  button,
  video,
  onClick
}: VideoSectionProps) {
  return (
    <div
      className="flex flex-col gap-6 lg:flex-row lg:gap-14"
      data-testid="ODMON-VideoSection"
    >
      <div className="lg:w-3/5">
        <NativeVideo
          captionsSrcUrl={video.captionsSrc}
          poster={video.posterImage}
          videoSrcUrlMp4={video.mp4Link}
          videoSrcUrlWebm={video.webmLink}
        />
      </div>
      <div className="flex flex-col justify-center lg:w-2/5">
        <div className="bg-primary-100 inline-block w-fit rounded-md p-1.5 text-xs font-medium text-[#0F2648] lg:py-0.5 lg:text-lg">
          NEW
        </div>
        <h1 className="font-arizona mb-0 mt-3 text-4xl lg:mt-4 lg:text-6xl">
          {title}
        </h1>
        <p className="mb-0 mt-3 text-base lg:mt-4 lg:text-lg">{text}</p>
        <button
          className="btn btn-solid-primary mt-5 block w-full lg:mt-6 lg:w-80"
          onClick={onClick}
        >
          {button.text}
        </button>
      </div>
    </div>
  )
}
