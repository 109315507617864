import { graphql, useStaticQuery } from 'gatsby'

import { parseJSONDataFromContentful } from '../../../../utils/parseJSONDataFromContentful'
import { PurchaseCameraScreenData, purchaseCameraScreenData } from './schema'

export const usePurchaseOutdoorCameraQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    #graphql
    query ODMONpurchaseOutdoorCamera {
      content: contentfulJson(contentful_id: { eq: "5Q72wN57M1UboiG5vyHxBC" }) {
        ...json
      }
      outdoorCameraPurchase: contentfulAsset(
        contentful_id: { eq: "7tKNjDX9lTzwA8RJWadX84" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
    }
  `)

  const content = parseJSONDataFromContentful<PurchaseCameraScreenData>(
    staticQuery,
    'content'
  )

  return purchaseCameraScreenData.parse({
    ...content,
    image: staticQuery.outdoorCameraPurchase
  })
}
