import { graphql, useStaticQuery } from 'gatsby'

export const use2gUpgradeFooterQuery = () => {
  return useStaticQuery(graphql`
    #graphql
    query Footer2gUpgradeQuery {
      footerData: contentfulLayout(
        contentful_id: { eq: "7MXykYxWepCkrZ7R52poqz" }
      ) {
        footer {
          legalLinks {
            text
            url
            trackingEvent
          }
          leadGenForm {
            title
            button {
              text
            }
            form {
              placeholder
              type
              title
            }
            note {
              raw # todo
            }
          }
          copyrightText
          socialLinks {
            text
            url
            icon {
              gatsbyImageData(placeholder: BLURRED)
              description
              title
            }
          }
          menus {
            title
            titleUrl
            links {
              ... on ContentfulLink {
                text
                url
                trackingEvent
              }
            }
          }
          disclaimerLink {
            ... on ContentfulLink {
              text
              url
            }
          }
        }
      }
    }
  `)
}
