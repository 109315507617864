import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'

interface Feature {
  readonly image: GatsbyImageSchema
  readonly title: string
  readonly description: string
  readonly bullets: readonly string[]
}

interface Props {
  readonly title: string
  readonly features: readonly Feature[]
}

export function FeatureDetails({ title, features }: Props) {
  return (
    <div>
      <h2 className="h2 mb-4 mt-0 text-center md:mb-8">{title}</h2>
      <div className="flex flex-col gap-10 md:grid md:grid-cols-2">
        {features.map((feature, cardIndex) => (
          <div className="flex flex-col" key={cardIndex}>
            <div className="rounded-xl">
              <GatsbyImage className="rounded-base" image={feature.image} />
            </div>
            <h3 className="m-0 mt-4 text-2xl md:mt-8 md:text-3xl">
              {feature.title}
            </h3>
            <p className="m-0 mt-4 text-base md:mt-5 md:text-lg">
              {feature.description}
            </p>
            <ul className="m-0 mt-2 text-base md:text-lg">
              {feature.bullets.map((featureBullet, index) => (
                <li className="mt-2 md:mt-0" key={index}>
                  {featureBullet}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
