import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import { pipe } from 'fp-ts/lib/function'

export function parseJSONDataFromContentful<T>(
  data: Record<string, unknown>,
  key: string
): T {
  return pipe(
    jsonSchema.parse(data[key]),
    parsedData => parsedData.content.internal.content,
    JSON.parse
  )
}
