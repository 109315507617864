import React from 'react'

import { Reminder as ReminderProps } from '../../hooks/useConfirmationPageQuery'

interface Props {
  readonly content: ReminderProps
}

function Reminder({ content }: Props) {
  const arrLength = content.items.length
  const getFirstColumn = () => content.items.slice(0, arrLength / 2)
  const getLastColumn = () => content.items.slice(-arrLength / 2)

  return (
    <div
      className="flex flex-col"
      data-component="ReminderSection"
      data-testid="ODMON-ReminderSection"
    >
      <h3 className="mb-4 mt-0 text-center text-2xl md:mb-8 md:text-3xl">
        {content.title}
      </h3>
      <div className="flex flex-col justify-center md:flex-row md:gap-16">
        <ul className="flex flex-col gap-8">
          {getFirstColumn().map((element, index) => (
            <li className="md:max-w-[400px]" key={index}>
              {element}
            </li>
          ))}
        </ul>
        <ul className="flex flex-col gap-8">
          {getLastColumn().map((element, index) => (
            <li className="md:max-w-[400px]" key={index}>
              {element}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Reminder
