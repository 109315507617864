import { useTrackingODMONParticipantAgreement } from '@ecomm/tracking'
import React, { ReactNode } from 'react'

import { FAQAnswer, List } from '../../hooks/useFaqsQuery'

interface Props {
  readonly answer: readonly ReadonlyArray<FAQAnswer>[]
  readonly questionNumber: number
}

function Answer({ answer, questionNumber }: Props) {
  const trackClick = useTrackingODMONParticipantAgreement()

  const replaceContent = (text: string) =>
    text.replace(/®/g, '<span class="align-super">®</span>')

  const renderContent = (answerParagraph: ReadonlyArray<FAQAnswer>) =>
    answerParagraph.map((answer: FAQAnswer, index: number) => {
      switch (answer.type) {
        case 'link':
          return (
            <a
              className={answer.className}
              href={answer.href}
              key={index}
              onClick={() => trackClick(answer.code, questionNumber)}
              rel="noopener noreferrer"
              target="_blank"
            >
              {replaceContent(answer.content)}
            </a>
          )
        case 'list':
          const listAnswer: List = answer
          return (
            <ul>
              {renderContent(listAnswer.content).map(
                (node: ReactNode, index: number) => (
                  <li key={index}>{node}</li>
                )
              )}
            </ul>
          )
        default:
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: replaceContent(answer.content)
              }}
              key={index}
            />
          )
      }
    })

  return (
    <div className="flex flex-col gap-1">
      {answer.map(
        (answerParagraph: ReadonlyArray<FAQAnswer | List>, index: number) => (
          <div className="pb-2" key={index}>
            {renderContent(answerParagraph)}
          </div>
        )
      )}
    </div>
  )
}

export default Answer
